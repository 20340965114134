import React, { useState, useEffect } from "react";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  TablePagination,
  IconButton,
  CircularProgress,
  Collapse,
  TableSortLabel,
  TextField,
  Dialog,
} from "@mui/material";
import {
  RestoreFromTrash,
  ExpandMore,
  ExpandLess,
  Edit,
  Delete as DeleteIcon,
  Restore as RestoreIcon,
} from "@mui/icons-material";
import TuneIcon from '@mui/icons-material/Tune';
import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// CSS Import
import "./Trash.css";
import axios from "axios";
import { baseURL } from "../baseURL.js";
import Fuse from "fuse.js";
import Confirmation from "./Confirm.jsx";
import CustomPagination from './Pagination/CustomPagination.js'
import FilterComponent from './FilterComponent.jsx';
import { sortBy } from "lodash";
import { useNavigate } from "react-router-dom";
const Trash = () => {
  const [data, setData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("company");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [open, setOpen] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setfilteredData] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [modalType, setModalType] = useState(""); // "company" or "hr"
  const [editData, setEditData] = useState({});
  const [showModal, setShowModal] = useState(false); // State for showing the modal
  const [deleteInfo, setDeleteInfo] = useState({}); // State to store delete info (id and type)
  const [totalHRs, setTotalHRs] = useState(0);
  const [companyToDelete, setCompanyToDelete] = useState(null);
  const [showRetrieveModal, setShowRetrieveModal] = useState(false); // Separate state for company modal
  const [isAddNewDialogoOpen, setIsAddNewDialogoOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filters, setFilters] = useState({
    branches: [],
    status: [],
    sortOrder: '',
  });

  const statuses = ["In Progress", "Cancelled", "Awaiting Confirmation", "On hold", "Completed"];
  const branchesList = ["CSE", "IT", "ME", "EE", "ECE", "CE"];

  const navigate = useNavigate();
  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${baseURL}/get-trash`, {
        headers: {
          authorization: localStorage.getItem("authToken"),
        },
        withCredentials: true,
      });

      let companiesWithHRs = Object.values(response.data).filter(
        (company) => company.HRs && company.HRs.length > 0
      );

      let total = 0;
      for (let i = 0; i < companiesWithHRs.length; i++) {
        total += companiesWithHRs[i].HRs.length;
      }

      if (filters.sortOrder) {
        companiesWithHRs = companiesWithHRs.sort((a, b) => {
          const dateA = new Date(a.lastContacted);
          const dateB = new Date(b.lastContacted);
          const createDateA = new Date(a.createdAt);
          const createDateB = new Date(b.createdAt);
    
          if (filters.sortOrder === 'Last Contacted') {
            // Sort valid dates first, followed by invalid dates
            if (!isNaN(dateB) && isNaN(dateA)) return 1;
            if (!isNaN(dateA) && isNaN(dateB)) return -1;
            return dateB - dateA;
          } else if (filters.sortOrder === 'First Contacted') {
            // Sort valid dates first, followed by invalid dates
            if (!isNaN(dateB) && isNaN(dateA)) return 1;
            if (!isNaN(dateA) && isNaN(dateB)) return -1;
            return dateA - dateB;
          } else if (filters.sortOrder === 'Last Added') {
            // Sort valid dates first, followed by invalid dates
            if (!isNaN(createDateB) && isNaN(createDateA)) return 1;
            if (!isNaN(createDateA) && isNaN(createDateB)) return -1;
            return createDateB - createDateA;
          } else if (filters.sortOrder === 'First Added') {
            // Sort valid dates first, followed by invalid dates
            if (!isNaN(createDateB) && isNaN(createDateA)) return 1;
            if (!isNaN(createDateA) && isNaN(createDateB)) return -1;
            return createDateA - createDateB;
          } else if (filters.sortOrder === 'A to Z') {
            return a.name.localeCompare(b.name);
          } else if (filters.sortOrder === 'Z to A') {
            return b.name.localeCompare(a.name);
          }
          return 0;
        });
      }

      setData(companiesWithHRs);
      setfilteredData(companiesWithHRs);
      setTotalHRs(total);
      
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(()=>{
    handleFilterChange(filters);
  }, [filters, data]);

  useEffect(() => {
    if (searchTerm) {
      const fuse = new Fuse(data, {
        keys: ["name", "HRs.name", "HRs.contact", "HRs.email"],
        includeScore: true,
        threshold: 0.3,
      });

      let result = fuse.search(searchTerm).map((result) => result.item);
      if (filters.sortOrder) {
        result = result.sort((a, b) => {
          const dateA = new Date(a.lastContacted);
          const dateB = new Date(b.lastContacted);
          const createDateA = new Date(a.createdAt);
          const createDateB = new Date(b.createdAt);
    
          if (filters.sortOrder === 'Last Contacted') {
            // Sort valid dates first, followed by invalid dates
            if (!isNaN(dateB) && isNaN(dateA)) return 1;
            if (!isNaN(dateA) && isNaN(dateB)) return -1;
            return dateB - dateA;
          } else if (filters.sortOrder === 'First Contacted') {
            // Sort valid dates first, followed by invalid dates
            if (!isNaN(dateB) && isNaN(dateA)) return 1;
            if (!isNaN(dateA) && isNaN(dateB)) return -1;
            return dateA - dateB;
          } else if (filters.sortOrder === 'Last Added') {
            // Sort valid dates first, followed by invalid dates
            if (!isNaN(createDateB) && isNaN(createDateA)) return 1;
            if (!isNaN(createDateA) && isNaN(createDateB)) return -1;
            return createDateB - createDateA;
          } else if (filters.sortOrder === 'First Added') {
            // Sort valid dates first, followed by invalid dates
            if (!isNaN(createDateB) && isNaN(createDateA)) return 1;
            if (!isNaN(createDateA) && isNaN(createDateB)) return -1;
            return createDateA - createDateB;
          } else if (filters.sortOrder === 'A to Z') {
            return a.name.localeCompare(b.name);
          } else if (filters.sortOrder === 'Z to A') {
            return b.name.localeCompare(a.name);
          }
          return 0;
        });
      }
      
      setfilteredData(result);
    } else {
      setfilteredData(data);
    }
    setPage(0);
  }, [searchTerm, data]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.HRs.map((hr) => hr._id));

      let newArr = [];
      for (var i = 0; i < newSelected.length; i++) {
        newArr = newArr.concat(newSelected[i]);
      }

      setSelected(newArr);
      return;
    }
    setSelected([]);
  };

  const handleDelete = (currSelected) => {
    setDeleteInfo([...currSelected]);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    try {
      setDeleting(true);
      const response = await axios.delete(`${baseURL}/delete-hrs`, {
        headers: {
          authorization: localStorage.getItem("authToken"),
          role: userType
        },
        data: { ids: deleteInfo }, // Pass selected HR ids for deletion
      });
      setSelected([]);
      getData();
    } catch (error) {
      console.error("An error occurred while deleting HRs.");
    } finally {
      setDeleting(false);
    }
  };

  const handleRetrieve = () => {
    setShowRetrieveModal(true);
  }

  const confirmRetrieve = async () => {
    try {
      const response = await axios.put(
        `${baseURL}/restore-hrs`,
        { ids: selected },
        {
          headers: {
            authorization: localStorage.getItem("authToken"),
          },
          withCredentials: true,
        }
      );
      setSelected([]);
      getData();
      navigate("/table"); // Navigate to the Table.js page
    } catch (error) {
      console.error("Error restoring HRs:", error);
      alert("An error occurred while restoring HRs.");
    }
  };
  //   const confirmModal = async (type, id) => {
  //   try {
  //     await axios.delete(`${baseURL}/delete-forever/${id}`, {
  //       headers: {
  //         authorization: localStorage.getItem("authToken"),
  //       },
  //       withCredentials: true,
  //     });
  //     setData(data.filter((item) => item._id !== id));
  //     setSelected(selected.filter((item) => item !== id));
  //   } catch (error) {
  //     console.error("Error deleting row:", error);
  //   } finally {
  //     setShowModal(false);
  //   }
  // };

  // const handleRestore = async (id) => {
  //   try {
  //     await axios.post(
  //       `${baseURL}/restore/${id}`,
  //       {},
  //       {
  //         headers: {
  //           authorization: localStorage.getItem("authToken"),
  //         },
  //         withCredentials: true,
  //       }
  //     );
  //     setData(data.filter((item) => item._id !== id));
  //   } catch (error) {
  //     console.error("Error restoring row:", error);
  //   }
  // };

  const handleClick = (event, id) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const sortCompanyData = () => {
    setfilteredData(sortBy(filteredData, ["name"]));
  };

  const handleSelectCompanyHRs = (event, company) => {
    if (event.target.checked) {
      const alreadySelected = selected.filter(
        (id) => !company.HRs.some((hr) => hr._id === id)
      );
      const newSelected = company.HRs.map((hr) => hr._id);
      setSelected((prevSelected) => [...alreadySelected, ...newSelected]);
    } else {
      const newSelected = selected.filter(
        (id) => !company.HRs.some((hr) => hr._id === id)
      );
      setSelected(newSelected);
    }
  };

  const handleFilterChange = (filters) => {
    setFilters(filters);
    let filtered = [...filteredData]; // assuming `data` is your original dataset

    if (filters.sortOrder) {
      filtered = filtered.sort((a, b) => {
        const dateA = new Date(a.lastContacted);
        const dateB = new Date(b.lastContacted);
        const createDateA = new Date(a.createdAt);
        const createDateB = new Date(b.createdAt);
  
        if (filters.sortOrder === 'Last Contacted') {
          // Sort valid dates first, followed by invalid dates
          if (!isNaN(dateB) && isNaN(dateA)) return 1;
          if (!isNaN(dateA) && isNaN(dateB)) return -1;
          return dateB - dateA;
        } else if (filters.sortOrder === 'First Contacted') {
          // Sort valid dates first, followed by invalid dates
          if (!isNaN(dateB) && isNaN(dateA)) return 1;
          if (!isNaN(dateA) && isNaN(dateB)) return -1;
          return dateA - dateB;
        } else if (filters.sortOrder === 'Last Added') {
          // Sort valid dates first, followed by invalid dates
          if (!isNaN(createDateB) && isNaN(createDateA)) return 1;
          if (!isNaN(createDateA) && isNaN(createDateB)) return -1;
          return createDateB - createDateA;
        } else if (filters.sortOrder === 'First Added') {
          // Sort valid dates first, followed by invalid dates
          if (!isNaN(createDateB) && isNaN(createDateA)) return 1;
          if (!isNaN(createDateA) && isNaN(createDateB)) return -1;
          return createDateA - createDateB;
        } else if (filters.sortOrder === 'A to Z') {
          return a.name.localeCompare(b.name);
        } else if (filters.sortOrder === 'Z to A') {
          return b.name.localeCompare(a.name);
        }
        return 0;
      });
    }

    setfilteredData(filtered); // assuming `setfilteredData` updates the displayed data in your table
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredData.length) : 0;

  const toggleRow = (id) => {
    setOpen((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  };

  const userType = "superadmin";

  return (
    <div className="table-container">
      <div className="table-controls">
      <input
        label=""
        placeholder="Search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-bar"
      />
        <div className="controls-right">
          <button className="table-btn back-button" onClick={() => navigate("/table")}>
            <ArrowBackIcon/>
            <span>Back</span>
          </button>
          {/* <button onClick={sortCompanyData} className="table-btn sort-button">
              <SortIcon/>
              <span>Sort Data</span>
            </button> */}
          <button
              className = "table-btn filter-button"
              variant="contained"
              color="primary"
              onClick={() => setFilterOpen(true)}
            >
              <TuneIcon/>
              Filter
          </button>
          <button 
            className="table-btn" 
            onClick={handleRetrieve}
            disabled={selected.length === 0}
          >
            <RestoreIcon />
            <span>Retrieve</span>
          </button>
          <button
            className="deletion-button"
            onClick={()=>handleDelete(selected)}
            disabled={deleting || selected.length === 0}
            >
            {deleting ? (
              <CircularProgress size={20} />
            ) : (
              <>
                <DeleteIcon />
                <span>Delete</span>
              </>
            )}
          </button>
        </div>   
      </div>
      {loading ? (
        <div className="loading-container">
          <CircularProgress />
        </div>
      ) : (
        <Paper className="paper-container" sx={{border:2, borderRadius:'0.5rem'}}>
          <TableContainer className="border-0 rounded-t-lg">
            <MuiTable className="data-table" aria-label="trash table">
              <TableHead className="table-head">
                <TableRow className="table-row">
                  <TableCell className="table-cell col1">
                    <Checkbox
                      color="primary"
                      indeterminate={
                        selected.length > 0 && selected.length < totalHRs
                      }
                      checked={data.length > 0 && selected.length === totalHRs}
                      onChange={handleSelectAllClick}
                      inputProps={{
                        "aria-label": "select all rows",
                      }}
                    />
                  </TableCell>
                  <TableCell className="table-cell !font-bold !text-lg">
                    DELETED COMPANIES
                  </TableCell>
                  <TableCell className="table-cell-actions"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    // const isItemSelected = isSelected(row._id);
                    return (
                      <React.Fragment key={row._id}>
                        <TableRow hover className="table-row">
                          <TableCell className="table-cell col1">
                            <IconButton onClick={() => toggleRow(row._id)}>
                              {open[row._id] ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                          </TableCell>
                          <TableCell className="table-cell">{row.name}</TableCell>
                          <TableCell className="table-cell-actions" align="right">
                            <Checkbox
                              color="primary"
                              indeterminate={
                                row.HRs.length === 0 ||
                                (selected.filter((id) =>
                                  row.HRs.some((hr) => hr._id === id)
                                ).length > 0 &&
                                  selected.filter((id) =>
                                    row.HRs.some((hr) => hr._id === id)
                                  ).length < row.HRs.length)
                              }
                              checked={row.HRs.every((hr) =>
                                selected.includes(hr._id)
                              )}
                              onChange={(event) =>
                                handleSelectCompanyHRs(event, row)
                              }
                              inputProps={{
                                "aria-label": "select all HRs for this company",
                              }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow className="table-row">
                          <TableCell
                            style={{padding: 0 }}
                            colSpan={5}
                          >
                            <Collapse
                              in={open[row._id]}
                              timeout="auto"
                              unmountOnExit
                            >
                        <TableContainer className="nested-table-container">
                          <MuiTable size="small" aria-label="hr details" className="nested-table">
                            <TableHead className="nested-table-head">
                              <TableRow className="nested-table-row">
                                <TableCell className="nested-table-cell !border-l-0">HR NAME</TableCell>
                                <TableCell className="nested-table-cell">HR CONTACT</TableCell>
                                <TableCell className="nested-table-cell">HR EMAIL</TableCell>
                                <TableCell className="nested-table-cell">HR LinkedIn</TableCell>
                                <TableCell className="nested-table-cell">HR LastContacted</TableCell>
                                <TableCell className="nested-table-cell-actions nested-table-cell"> </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {Array.isArray(row.HRs) &&
                                      row.HRs.map((hr) => (
                                        <TableRow key={hr._id} className="nested-table-row">
                                          <TableCell className="nested-table-cell !border-l-0">{hr.name}</TableCell>
                                          <TableCell className="nested-table-cell">{hr.phone}</TableCell>
                                          <TableCell className="nested-table-cell">{hr.email}</TableCell>
                                          <TableCell className="nested-table-cell">
                                            <a
                                              href={hr.linkedIn}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="linkedin-link"
                                            >
                                              {hr.linkedIn ? "LinkedIn " : "No LinkedIn"}
                                            </a>
                                          </TableCell>                                          
                                          <TableCell className="nested-table-cell">  
                                            {hr.lastContacted ? 
                                              new Date(hr.lastContacted).toLocaleString(undefined, {
                                                year: 'numeric',
                                                month: 'numeric',
                                                day: 'numeric',
                                                hour: 'numeric',
                                                minute: 'numeric',
                                                hour12: true,
                                              }) : "Not Contacted"
                                            }
                                          </TableCell>
                                          <TableCell className="nested-table-cell-actions nested-table-cell" align="right">
                                            <IconButton
                                              onClick={() =>
                                                handleDelete([hr._id])
                                              }
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                            <Checkbox
                                              color="primary"
                                              checked={isSelected(hr._id)}
                                              onClick={(event) =>
                                                handleClick(event, hr._id)
                                              }
                                            />
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                  </TableBody>
                                </MuiTable>
                              </TableContainer>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={15} />
                  </TableRow>
                )}
              </TableBody>
            </MuiTable>
          </TableContainer>
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className="pagination-bar"
          /> */}
          <CustomPagination
            rowsPerPage={rowsPerPage}
            totalRows={filteredData.length}
            currentPage={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}

      <Dialog 
    open={filterOpen} 
    onClose={() => setFilterOpen(false) } 
    PaperProps={{ sx: { borderRadius: "1.5rem" } }}
  >
    <FilterComponent 
      branches={branchesList} 
      statuses={statuses} 
      appliedFilters={filters}
      onFilterChange={handleFilterChange} 
      onClose={() => setFilterOpen(false)} // Pass the onClose function
      showStatuses={false}
      showBranches={false}
    />
  </Dialog>      

      <Confirmation
        showModal={showModal}
        hideModal={() => setShowModal(false)}
        confirmModal={() => confirmDelete()}
        toDelete={deleteInfo}
        role={userType}
        message="Are you sure you want to Permanently delete Selected Records?"
        buttonName="Delete"
      />
      <Confirmation
        showModal={showRetrieveModal}
        hideModal={() => setShowRetrieveModal(false)}
        confirmModal={() => confirmRetrieve()}
        role={userType}
        toDelete={selected}
        message="Are you sure you want to Retrieve Selected Records?"
        buttonName="Retrieve"
      />
    </div>
  );
};

export default Trash;
