import React, { useState, useEffect } from "react";
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  Checkbox,
  CircularProgress,
  IconButton,
  // Collapse,
  // TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Chip,
} from "@mui/material";
import {
  DeleteOutline,
  Edit,
} from "@mui/icons-material";
import TuneIcon from '@mui/icons-material/Tune';
import CustomPagination from './Pagination/CustomPagination.js'
// import SearchIcon from '@mui/icons-material/Search';
// import SortIcon from '@mui/icons-material/Sort';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import { DatePicker } from "@mui/lab"; // Import DatePicker from @mui/lab
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import "./Table.css";
import axios from "axios";
import FilterComponent from './FilterComponent.jsx';
import { baseURL } from "../baseURL.js";
import Fuse from "fuse.js";
import DeleteConfirmation from "./Confirm.jsx";
// import { sortBy } from "lodash";
import CustomDialog from "./CustomDialog/CustomDialog.js";

const Table = () => {
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setfilteredData] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [totalCompanies, setTotalCompanies] = useState(0);
  const [companyToDelete, setCompanyToDelete] = useState(null);
  const [showCompanyModal, setShowCompanyModal] = useState(false); // Separate state for company modal
  const [isAddNewDialogoOpen, setIsAddNewDialogoOpen] = useState(false);
  const [noValidEmail, showNoValidEmail] = useState(false);
  const [noValidPhone, showNoValidPhone] = useState(false);
  // const [editingDate, setEditingDate] = useState(null);
  // const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterOptionData, setFilterOptionData] = useState([]);
  const [changingStatus, setChangingStatus] = useState(false);
  const [updateStatusCompanyId, setUpdateStatusCompanyId] = useState(null);
  const [updatingDetails, setUpdatingDetails] = useState(false);
  const [filters, setFilters] = useState({
    branches: [],
    status: [],
    sortOrder: '',
  });
  const [emptyRows, setEmptyRows] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
    

  const statuses = ["Completed","In Progress", "Cancelled", "Awaiting Confirmation", "On hold" ];
  // Define your color mapping for statuses
  const statusColors = {
    "Completed": "green",
    "In Progress": "orange",
    "Cancelled": "red",
    "Awaiting Confirmation": "blue",
    "On hold": "cyan",
    
  };
  const branchesList = ["CSE", "IT", "ME", "EE", "ECE", "CE"];
  
  const getData = async () => {
    try {
      setLoading(true);
      let res = await axios.get(baseURL + '/companies', {
        headers: { authorization: localStorage.getItem("authToken") }
      });
      
       // Filter to show only rows with non-empty branches
      res.data = res.data.filter((company) => company.branches && company.branches.length > 0);

      setPage(0);
      setData(res.data);
      setfilteredData(res.data);
      setFilterOptionData(res.data);
      setTotalCompanies(res.data.length);
      setEmptyRows(Math.max(0, (1) * rowsPerPage - filteredData.length))
    } catch (error) {
      console.error("Error fetching companies", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  },[]);

  useEffect(()=>{
    handleFilterChange(filters);
  }, [filters, data]);

  // Search Perform
  useEffect(() => {
    const performSearch = () => {
      if (searchTerm) {
        const fuse = new Fuse(filterOptionData, {
          keys: ["name", "phone", "email", "status", "branches"],
          includeScore: true,
          threshold: 0.3,
        });
  
        let result = fuse.search(searchTerm).map((result) => result.item);
        if (filters.sortOrder) {
          result = result.sort((a, b) => {
            const dateA = new Date(a.lastContacted);
            const dateB = new Date(b.lastContacted);
            const createDateA = new Date(a.createdAt);
            const createDateB = new Date(b.createdAt);
      
            if (filters.sortOrder === 'Last Contacted') {
              // Sort valid dates first, followed by invalid dates
              if (!isNaN(dateB) && isNaN(dateA)) return 1;
              if (!isNaN(dateA) && isNaN(dateB)) return -1;
              return dateB - dateA;
            } else if (filters.sortOrder === 'First Contacted') {
              // Sort valid dates first, followed by invalid dates
              if (!isNaN(dateB) && isNaN(dateA)) return 1;
              if (!isNaN(dateA) && isNaN(dateB)) return -1;
              return dateA - dateB;
            } else if (filters.sortOrder === 'Last Added') {
              // Sort valid dates first, followed by invalid dates
              if (!isNaN(createDateB) && isNaN(createDateA)) return 1;
              if (!isNaN(createDateA) && isNaN(createDateB)) return -1;
              return createDateB - createDateA;
            } else if (filters.sortOrder === 'First Added') {
              // Sort valid dates first, followed by invalid dates
              if (!isNaN(createDateB) && isNaN(createDateA)) return 1;
              if (!isNaN(createDateA) && isNaN(createDateB)) return -1;
              return createDateA - createDateB;
            } else if (filters.sortOrder === 'A to Z') {
              return a.name.localeCompare(b.name);
            } else if (filters.sortOrder === 'Z to A') {
              return b.name.localeCompare(a.name);
            }
            return 0;
          });
        }

        setfilteredData(result);
      } else {
        setfilteredData(filterOptionData);
      }
      setPage(0); // Reset page when search changes
      setEmptyRows(Math.max(0, (1) * rowsPerPage - filteredData.length))
    };
  
    performSearch();
  }, [filterOptionData, filteredData.length, rowsPerPage, searchTerm, data]); // Ensure both searchTerm and data are dependencies
  
  // const sortCompanyData = (reverse="false") => {
  //   if(reverse===false){
  //     setfilteredData(sortBy(filteredData, ["name"]));
  //   } else{
  //     setfilteredData(sortBy(filteredData, ["name"]).reverse());
  //   }
  // };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  
  const handleFilterChange = (filters) => {
    setFilters(filters);
    let filtered = [...data]; // assuming `data` is your original dataset

    if (filters.branches.length) {
      filtered = filtered.filter(company =>
        filters.branches.some(branch => company.branches.includes(branch))
      );
    }

    if(filters.status.length){
      filtered = filtered.filter(company => 
        filters.status.some(oneStatus => company.status.includes(oneStatus))
      );
    }

    if (filters.sortOrder) {
      filtered = filtered.sort((a, b) => {
        const dateA = new Date(a.lastContacted);
        const dateB = new Date(b.lastContacted);
        const createDateA = new Date(a.createdAt);
        const createDateB = new Date(b.createdAt);
  
        if (filters.sortOrder === 'Last Contacted') {
          // Sort valid dates first, followed by invalid dates
          if (!isNaN(dateB) && isNaN(dateA)) return 1;
          if (!isNaN(dateA) && isNaN(dateB)) return -1;
          return dateB - dateA;
        } else if (filters.sortOrder === 'First Contacted') {
          // Sort valid dates first, followed by invalid dates
          if (!isNaN(dateB) && isNaN(dateA)) return 1;
          if (!isNaN(dateA) && isNaN(dateB)) return -1;
          return dateA - dateB;
        } else if (filters.sortOrder === 'Last Added') {
          // Sort valid dates first, followed by invalid dates
          if (!isNaN(createDateB) && isNaN(createDateA)) return 1;
          if (!isNaN(createDateA) && isNaN(createDateB)) return -1;
          return createDateB - createDateA;
        } else if (filters.sortOrder === 'First Added') {
          // Sort valid dates first, followed by invalid dates
          if (!isNaN(createDateB) && isNaN(createDateA)) return 1;
          if (!isNaN(createDateA) && isNaN(createDateB)) return -1;
          return createDateA - createDateB;
        } else if (filters.sortOrder === 'A to Z') {
          return a.name.localeCompare(b.name);
        } else if (filters.sortOrder === 'Z to A') {
          return b.name.localeCompare(a.name);
        }
        return 0;
      });
    }

    setEmptyRows(Math.max(0, (1 + page) * rowsPerPage - filteredData.length))
    setFilterOptionData(filtered);
    setfilteredData(filtered); // assuming `setfilteredData` updates the displayed data in your table
  };

  const handleDeleteCompany = async (companiesToDelete) => {
    try {
      setDeleting(true);
      await axios.delete(`${baseURL}/delete-companies`, {
        headers: {
          authorization: localStorage.getItem("authToken"),
        },
        withCredentials: true,
        data: {
          ids: companiesToDelete,
        },
      });
      setSelected([])
      getData(); // Refresh the data after deletion
    } catch (error) {
      console.error("Error deleting company:", error);
    } finally {
      setShowCompanyModal(false);
      setDeleting(false);
    }
  };

  const confirmDeleteCompany = (companiesToDelete) => {
    setCompanyToDelete(companiesToDelete);
    setShowCompanyModal(true);
  };

  const addNewCompany = () => {
    setIsAddNewDialogoOpen(true);
  };
  
  const handleChangePage = (newPage) => {
    setPage(newPage);
    setEmptyRows(Math.max(0, (1 + newPage) * rowsPerPage - filteredData.length))
  };

  const handleChangeRowsPerPage = (value) => {
    setEmptyRows(Math.max(0, (1) * rowsPerPage - filteredData.length));
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
  };
  
  const userType = localStorage.getItem("userType");

  const handleOpenModal = (item, type) => {
    setEditData(item);
    setIsDialogOpen(true);
  };

  const handleCloseModal = () => {
    setIsDialogOpen(false);
    setEditData({});
    setErrorMessage(" ");
  };

  const handleEditChange = (event) => {
    const { name, value } = event.target;
    if (name === "phone") {
      const numericValue = value.replace(/[^0-9]/g, ""); // Keep only numeric values
        if (numericValue.length <= 10) {
        setEditData((prevData) => ({ ...prevData, [name]: numericValue }));
      }
    } 
    else {
      setEditData((prevData) => ({ ...prevData, [name]: value }));
     }
  };

  const handleBranchChange = (event) => {
    const { value } = event.target;
    const branches = value.split(',').map(branch => branch.trim());
    setEditData((prevData) => ({ ...prevData, branches }));
  };

  const handleStatusChange = async (companyId, newStatus) => {
    try {
      setChangingStatus(true);
      setUpdateStatusCompanyId(companyId);
      const res = await axios.put(`${baseURL}/company/${companyId}`, { status: newStatus }, {
        headers: { authorization: localStorage.getItem("authToken") }
      });
      setData(data.map(company => company._id === companyId ? res.data.company : company));
      setFilterOptionData(filterOptionData.map(company => company._id === companyId ? res.data.company : company));
      setfilteredData(filteredData.map(company => company._id === companyId ? res.data.company : company));
    } catch (error) {
      console.error("Error updating status", error);
    } finally {
      setChangingStatus(false);
      setUpdateStatusCompanyId(null);
    }
  };
  
  const handleBlur = (event) => {
    const { name, value } = event.target;
  
    if (name === "email") {
      const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      const isValid = value === "" || isValidEmail.test(value);
      showNoValidEmail(!isValid);
    }else if (name === "phone") {
      const isValidPhonePattern = /^[0-9]{10}$/;
      const isValid = value === "" || isValidPhonePattern.test(value);
      showNoValidPhone(!isValid);
    }
  };

  const handleSaveChanges = async () => {
    if(updatingDetails){
      return;
    }
    if(!editData) return;
    setUpdatingDetails(true);
    setErrorMessage("");
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const email = editData.email || "";
    const isValid = email === "" || isValidEmail.test(email);
    
    const isValidPhone = /^[0-9]{10}$/; // Assuming a 10-digit phone number
    const phone = editData.phone || "";
    const isPhoneValid = phone === "" || isValidPhone.test(phone);
  
    if (!isValid) {
      setUpdatingDetails(false);
      showNoValidEmail(true); // Show error message if email is invalid
      return; // Prevent saving changes
    }
  
    if (!isPhoneValid) {
      setUpdatingDetails(false);
      showNoValidPhone(true); // Show error message if email is invalid
      return; // Prevent saving changes
    }
    
    let response;
    try {
      response = await axios.put(`${baseURL}/company/${editData._id}`, editData, {
        headers: {
          authorization: localStorage.getItem("authToken"),
        },
        withCredentials: true,
      });
      getData();
      handleCloseModal();
      setErrorMessage("");
    } catch (error) {
      const { response } = error;
      if (response && response.status === 400) {
        setErrorMessage(response.data.message); // Set error message from backend
      } else {
        setErrorMessage("An unexpected error occurred."); // Handle any other errors
      }
    } finally {
      setUpdatingDetails(false);
    }
  };

  return (
    <div className="table-container">
      <CustomDialog
        isOpen={isAddNewDialogoOpen}
        setIsOpen={setIsAddNewDialogoOpen}
        getData={getData}
        type="company"
      />
      <div className="table-controls">
      <input
        label=""
        placeholder="Search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-bar"
      />
        {(userType === "superadmin" ) && (
          <div className="controls-right">
            <button
              className = "table-btn filter-button"
              variant="contained"
              color="primary"
              onClick={() => setFilterOpen(true)}
            >
              <TuneIcon/>
              Filter
            </button>
           {/* {( userType==="superadmin" ||userType==="admin") && <button onClick={()=>sortCompanyData()} className="table-btn sorter-button">
              <SortIcon/>
              <span>Sort Data</span>
            </button>} */}
            {( userType==="superadmin" ||userType==="admin") && <button className="table-btn add-button" onClick={addNewCompany}>
              <AddCircleOutlineIcon/>
              Add new
            </button>}
            <button
              className="deletion-button"
              onClick={() => confirmDeleteCompany(selected)}
              disabled={deleting || selected.length === 0}
            >
              {deleting ? (
                <CircularProgress size={20} />
              ) : (
                <>
                  <DeleteOutline />
                  <span>Delete</span>
                </>
              )}
            </button>
          </div>
        )}
      </div>
      {userType==="superadmin"? (loading ? (
        <div className="loading-container">
          <CircularProgress />
        </div>
      ) : (
        <Paper className="paper-container" sx={{border:2, borderRadius:'0.5rem'}}>
        <TableContainer className="border-0 rounded-t-lg">
          <MuiTable className="data-table" aria-label="simple table">
            <TableHead className="table-head">
              <TableRow className="table-row">
                <TableCell className="table-cell col1">
                    <Checkbox
                      color="primary"
                      indeterminate={
                        selected.length > 0 && selected.length < totalCompanies
                      }
                      checked={data.length > 0 && selected.length === totalCompanies}
                      onChange={handleSelectAllClick}
                      inputProps={{
                        "aria-label": "select all rows",
                      }}
                    />
                  </TableCell>
                  <TableCell className="table-cell">COMPANY</TableCell>
                  <TableCell className="table-cell">Email</TableCell>
                  <TableCell className="table-cell">Phone</TableCell>
                  <TableCell className="table-cell">Branch</TableCell>
                  <TableCell className="table-cell">Last Contacted</TableCell>
                  <TableCell className="table-cell">Status</TableCell>
                  <TableCell className="table-cell-actions" align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((company) => {
                    // const hrCount = Array.isArray(row.HRs) ? row.HRs.length : 0;
                    return (
                      <React.Fragment key={company._id}>
                        <TableRow hover className="table-row">
                          <TableCell className="table-cell col1">
                            <Checkbox
                              color="primary"
                              checked={isSelected(company._id)}
                              onClick={(event) =>
                                handleClick(event, company._id)
                              }
                            />
                          </TableCell>
                          <TableCell className="table-cell">{company.name}</TableCell>
                          <TableCell className="table-cell">{company.email}</TableCell>
                          <TableCell className="table-cell">{company.phone}</TableCell>
                          <TableCell className="table-cell">
                            {company.branches.map((branch, index) => (
                              <Chip
                                key={index}
                                label={branch}
                                variant="filled"
                                color="primary"
                                style={{ marginRight: 4, marginBottom: 4 }}
                              />
                            ))}
                          </TableCell>
                          {/* <TableCell>{company.branches.join(', ')}</TableCell> */}
                          {/* <TableCell>
                            {editDateId === company._id ? (
                              <>
                                <TextField
                                  type="date"
                                  value={newDate}
                                  onChange={(e) => setNewDate(e.target.value)}
                                />
                                <Button onClick={() => handleDateChange(company._id, newDate)}>Save</Button>
                                <Button onClick={() => setEditDateId(null)}>Cancel</Button>
                              </>
                            ) : (
                              <>
                                {isValidDate(company.lastContacted) ? new Date(company.lastContacted).toLocaleString() : "N/A"}
                                <IconButton
                                  onClick={() => {
                                    setEditDateId(company._id);
                                    setNewDate(isValidDate(company.lastContacted) ? new Date(company.lastContacted).toISOString().split('T')[0] : "");
                                  }}
                                >
                                  <CalendarToday />
                                </IconButton>
                              </>
                            )}
                          </TableCell> */}
                          {/* <TableCell>
                            {editDateId === company._id ? (
                              <>
                                <TextField
                                  type="date"
                                  value={newDate}
                                  onChange={(e) => setNewDate(e.target.value)}
                                />
                                <Button onClick={() => handleDateChange(company._id, newDate)}>Save</Button>
                                <Button onClick={() => setEditDateId(null)}>Cancel</Button>
                              </>
                            ) : (
                              <>
                                {new Date(company.lastContacted).toLocaleString()}
                                <IconButton onClick={() => { setEditDateId(company._id); setNewDate(new Date(company.lastContacted).toISOString().split('T')[0]) }}>
                                  <CalendarToday />
                                </IconButton>
                              </>
                            )}
                          </TableCell> */}
                          {/* <TableCell>
                            {editingDate === company._id ? (
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                  value={new Date(company.lastContacted)}
                                  onChange={(newDate) => handleDateChange(company._id, newDate)}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </LocalizationProvider>
                            ) : (
                              <>
                                {new Date(company.lastContacted).toLocaleString()}
                                <IconButton onClick={() => setEditingDate(company._id)}>
                                  <Edit />
                                </IconButton>
                              </>
                            )}
                          </TableCell> */}
                          <TableCell className="table-cell">
                            {company.lastContacted? new Date(company.lastContacted).toLocaleString(undefined, {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              hour12: true, // 12-hour clock
                            }): "Not Contacted"}
                          </TableCell>
                          <TableCell className="table-cell">
                            { changingStatus && company._id === updateStatusCompanyId ?
                              <CircularProgress size={10} />
                              :
                              <Select
                                value={company.status || "In Progress"}
                                onChange={(e) => handleStatusChange(company._id, e.target.value)}
                                className="status-select"
                              >
                                {Object.entries(statusColors).map(([status, color]) => (
                                <MenuItem key={status} value={status} className="status-select-item">
                                  {/* Colored dot before the status text */}
                                  <span
                                    style={{
                                      backgroundColor: color,
                                      borderRadius: "50%",
                                      border: '0.0px solid black',
                                      display: "inline-block",
                                      width: "9px",
                                      height: "9px",
                                      marginRight: "8px",
                                    }}
                                  ></span>
                                  {status}
                                </MenuItem>
                              ))}
                              </Select>}
                          </TableCell>
                          <TableCell className="table-cell-actions">
                            {/* {editingCompanyId === company._id ? (
                              <Button onClick={() => handleSave(company._id, { status: company.status })}>Save</Button>
                            ) : (
                              <Button onClick={() => handleEdit(company._id)}>Edit</Button>
                            )} */}
                            <IconButton onClick={() => handleOpenModal(company, "company")}>
                              <Edit />
                            </IconButton>
                          </TableCell>
                          {/* <TableCell align="right">
                            <IconButton
                              onClick={() => handleOpenModal(company, "company")}
                            >
                              <Edit />
                            </IconButton>
                          </TableCell> */}
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={15} />
                  </TableRow>
                )}
              </TableBody>
            </MuiTable>
          </TableContainer>
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className="pagination-bar"
          /> */}
          <CustomPagination
            rowsPerPage={rowsPerPage}
            totalRows={filteredData.length}
            currentPage={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className="custom-pagination"
          />
        </Paper>
      )): null}

      {/* Dialog for Editing */}
<Dialog
  open={isDialogOpen}
  onClose={handleCloseModal}
  maxWidth="sm"
  fullWidth
  className="edit-dialog"
  PaperProps={{ sx: { borderRadius: "1.5rem" } }}
>
  <div className="inside-dialog">
  <DialogTitle className="edit-dialog-title">
    <Edit/> Edit Company
  </DialogTitle>
  <hr className="edit-dialog-divider"/>
  <DialogContent className="edit-dialog-content">
  <div className="edit-label-input-group">
    <label className="edit-label">Company</label>
    <input
      name="name"
      value={editData.name || ""}
      onChange={handleEditChange}
      fullWidth
      margin="normal"
      className="edit-input"
    />
  </div>
  <div className="edit-label-input-group">
    <label className="edit-label">Email</label>
    <input
      name="email"
      value={editData.email || ""}
      onChange={handleEditChange}
      onBlur={handleBlur}
      fullWidth
      placeholder="user@example.com"
      type="email"
      margin="normal"
      required
      className="edit-input"
    />
  </div>
    {noValidEmail && <p className="invalid-details-text">Invalid email address.</p>}
    <div className="edit-label-input-group">
      <label className="edit-label">Phone</label>
    <input
      name="phone"
      value={editData.phone || ""}
      onChange={handleEditChange}
      onBlur={handleBlur}
      fullWidth
      placeholder="1234567890"
      type="phone"
      margin="normal"
      required
      className="edit-input"
    />
  </div>
    {noValidPhone && <p className="invalid-details-text">Invalid phone number. Must be 10 digits.</p>}
    <div className="edit-label-input-group">
      <label className="edit-label">Branches</label>
      <input
        name="branch"
        value={editData.branches ? editData.branches.join(', ') : ""}
        onChange={handleBranchChange}
        fullWidth
        margin="normal"
        className="edit-input"
    />
    </div>
    <div className="edit-label-input-group">
      <label className="edit-label">Last Contacted</label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          value={editData.lastContacted ? dayjs(editData.lastContacted) : null}
          onChange={(newValue) => handleEditChange({ target: { name: 'lastContacted', value: newValue } })}
          fullWidth
          className="edit-input !w-[calc(100%-100px)] !p-0 !border !border-[#111] edit-lastcontacted-input"
          renderInput={(params) => <input 
                                    {...params} 
                                    fullWidth 
                                    margin="normal" 
                                    className="edit-input !w-[100%] !border !border-[#111]"
                                  />}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
        />
      </LocalizationProvider>
    </div>
  <div className="edit-label-input-group">
    <label className="edit-label">Status</label>
    <Select
      name="status"
      value={editData.status || ""}
      onChange={handleEditChange}
      margin="normal"
      className="edit-input"
    >
      {Object.entries(statusColors).map(([status, color]) => (
        <MenuItem key={status} value={status} className="status-select-item">
          {/* Colored dot before the status text */}
          <span
            style={{
              backgroundColor: color,
              borderRadius: "50%",
              border: '0.0px solid black',
              display: "inline-block",
              width: "9px",
              height: "9px",
              marginRight: "8px",
            }}
          ></span>
          {status}
        </MenuItem>
      ))}
    </Select>
  </div>
  {errorMessage && <p className="invalid-details-text mt-2 ml-4">{errorMessage}</p>}
  </DialogContent>
  <DialogActions className="edit-dialog-actions">
    <Button onClick={handleSaveChanges} className="edit-ok-button" disabled={updatingDetails}>
    {
    updatingDetails?
      <CircularProgress size={10} />
      :"OK"
    }
    </Button>
    <Button onClick={handleCloseModal} className="edit-cancel-button" disabled={updatingDetails}>
      Cancel
    </Button>
  </DialogActions>
  </div>
</Dialog>
<Dialog open={filterOpen} onClose={() => setFilterOpen(false)} PaperProps={{ sx: { borderRadius: "1.5rem" } }}>
  <FilterComponent 
    branches={branchesList} 
    statuses={statusColors} 
    appliedFilters={filters}
    onFilterChange={handleFilterChange} 
    onClose={() => setFilterOpen(false)} // Pass the onClose function
  />
</Dialog>
      <DeleteConfirmation
        showModal={showCompanyModal}
        hideModal={() => setShowCompanyModal(false)}
        confirmModal={() => handleDeleteCompany(companyToDelete)}
        toDelete={companyToDelete}
        role={userType}
        message="Are you sure you want to Delete Selected Company(s)?"
      />
    </div>
  );
};

export default Table;