import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, CircularProgress } from '@mui/material';
import { Link } from "react-router-dom";
import './Confirm.css';

const DeleteConfirmation = ({ showModal, hideModal, confirmModal, role, toDelete, message, buttonName="Delete", deletingFromTable = false }) => {
    const [loading, setLoading] = useState(false);

    const handleDelete = async () => {
        setLoading(true);
        await confirmModal(toDelete, role);
        setLoading(false);
        hideModal();
    };

    return (
        <Dialog 
            open={showModal} 
            onClose={hideModal} 
            disableBackdropClick={loading} 
            disableEscapeKeyDown={loading} 
            PaperProps={{ sx: { borderRadius: "1.5rem" } }}
        >
            <div className="inside-dialog">
            <DialogTitle className="dialog-title">{buttonName} Record?</DialogTitle>
            <hr className="hr-divider"/>
            <DialogContent>
                <Typography variant="body1" className="main-message">
                    {message}
                </Typography>
                {deletingFromTable && <Typography className="extra-message">
                    If you Delete, the entry(s) will go to {role==="superadmin"?<Link to="/trash" className="link-part">Trash</Link>: "Trash"}
                </Typography>}
            </DialogContent>
            <DialogActions className="dialog-actions">
                <Button onClick={hideModal} color="primary" disabled={loading} className="cancel-button">
                    Cancel
                </Button>
                <Button 
                    onClick={handleDelete} 
                    color="error" 
                    variant="contained" 
                    disabled={loading} 
                    className="accept-button"
                >
                    {loading ? <CircularProgress size={20} /> : buttonName}
                </Button>
            </DialogActions>
            </div>
        </Dialog>
    );
}

export default DeleteConfirmation;
