import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import Fuse from "fuse.js";
import { IoMdMail } from "react-icons/io";
import { MdPhoneInTalk } from "react-icons/md";
import { FaLinkedin } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa";
import { HiDotsCircleHorizontal } from "react-icons/hi";
import { FiTable } from "react-icons/fi";

import {
    Table as MuiTable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    CircularProgress,
    IconButton,
    Collapse,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Autocomplete,
    Typography, 
} from "@mui/material";

import { Edit } from '@mui/icons-material';

import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import './MobileTable.css'; 
import { baseURL } from "../../baseURL.js";
import MobilePagination from '../Pagination/MobilePagination.js';
import SearchInput from '../MobileSearchFilterComponent/SearchInput.jsx';

const MobileTable = ({isFocused, setIsFocused}) => {
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setfilteredData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("company");
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [open, setOpen] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [modalType, setModalType] = useState(""); // "company" or "hr"
  const [editData, setEditData] = useState({});
  const [showModal, setShowModal] = useState(false); // State for showing the modal
  const [deleteInfo, setDeleteInfo] = useState({}); // State to store delete info (id and type)
  const [totalHRs, setTotalHRs] = useState(0);
  const [companyToDelete, setCompanyToDelete] = useState(null);
  const [showCompanyModal, setShowCompanyModal] = useState(false); // Separate state for company modal
  const [isAddNewDialogoOpen, setIsAddNewDialogoOpen] = useState(false);
  const [noValidEmail, showNoValidEmail] = useState(false);
  const [noValidPhone, showNoValidPhone] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [activeHR, setActiveHR] = useState(null); 
  const [updatingDetails, setUpdatingDetails] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filters, setFilters] = useState({
    branches: [],
    status: [],
    sortOrder: '',
  });
  const [lastContactedPopupVisible, setLastContactedPopupVisible] = useState(false);
  const [updatingLastContacted, setUpdatingLastContacted] = useState(false);
  const [lastContactedUpdatingHrId, setLastContactedUpdatingHrId] = useState(null);
  const [hrResponse, setHrResponse] = useState("");

  const [openResponseDialog, setOpenResponseDialog] = useState(false); // State to manage dialog open/close
  const [updatingResponse, setUpdatingResponse] = useState(false);
  const [responseUpdatingHrId, setResponseUpdatingHrId] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const statuses = ["In Progress", "Cancelled", "Awaiting Confirmation", "On hold", "Completed"];
  const branchesList = ["CSE", "IT", "ME", "EE", "ECE", "CE"];

  const hrResponses = [
    // Try Later
    { group: 'Try Later', title: 'Asked to Call Later' },
    { group: 'Try Later', title: 'Busy' },
    { group: 'Try Later', title: 'Call Declined' },
    { group: 'Try Later', title: 'Call Didn\'t Connect' },
    { group: 'Try Later', title: 'Could Not Hear' },
    { group: 'Try Later', title: 'Network Issue' },
    { group: 'Try Later', title: 'Number Not Reachable' },
    { group: 'Try Later', title: 'Switched Off' },
    { group: 'Try Later', title: 'Will Call Back soon' },
  
    // Invalid
    { group: 'Invalid', title: 'Invalid Number' },
    { group: 'Invalid', title: 'Number Not Exist' },
    { group: 'Invalid', title: 'Number Out of Service' },
    { group: 'Invalid', title: 'Person Not in that Company' },
    { group: 'Invalid', title: 'Person Not Handling Recruitments' },
    { group: 'Invalid', title: 'Wrong Person' },
  
    // Negative Response
    { group: 'Negative Response', title: 'Done with Hiring' },
    { group: 'Negative Response', title: 'Hiring Only Experienced Persons' },
    { group: 'Negative Response', title: 'No Positions Available' },
    { group: 'Negative Response', title: 'Not Currently Hiring' },
    { group: 'Negative Response', title: 'Not Doing Placements This Year' },
    { group: 'Negative Response', title: 'Not Hiring Freshers' },
    { group: 'Negative Response', title: 'Not Interested for Anything' },
    { group: 'Negative Response', title: 'Not Interested in Campus Hiring' },
  
    // Positive Response
    { group: 'Positive Response', title: 'Email Provided for sharing Details' },
    { group: 'Positive Response', title: 'Shared Recruitment Team Email' },
    { group: 'Positive Response', title: 'Shared TPO Details, will contact us' },
    { group: 'Positive Response', title: 'Will Message Details' },
  
    // Interested In
    { group: 'Interested In', title: 'Interested in Placements' },
    { group: 'Interested In', title: 'Interested in Internships' },
    { group: 'Interested In', title: 'Interested in Training' },
    { group: 'Interested In', title: 'Interested in Conducting PPT' },
  
    // Other
    { group: 'Other', title: 'Not Now, but will Inform if Hiring Needed' },
    { group: 'Other', title: 'Will Ask HR to provide details or not' },
    { group: 'Other', title: 'Will Check and Inform' },
    { group: 'Other', title: 'Will Convey Message to HR' },
  ];

  const observer = useRef();

  const lastElement = (node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setPage((prevPageNumber) => prevPageNumber + 1);
      }
    });
    if (node) observer.current.observe(node);
  };
  
    const getData = async () => {
        try {
          setLoading(true);
          const response = await axios.get(`${baseURL}/get-data/query?page=${page}&limit=30&sort=${filters.sortOrder}&search=${searchTerm}`, {
            headers: {
              authorization: localStorage.getItem("authToken"),
            },
            withCredentials: true,
          });
    
          let companiesWithHRs = Object.values(response.data.companies).filter(
            (company) => company.HRs && company.HRs.length > 0
          );
    
          let total = 0;
          for (let i = 0; i < companiesWithHRs.length; i++) {
            total += companiesWithHRs[i].HRs.length;
          }

          setHasMore(response.data.companies.length > 0);

          setData((prevCompanies) => {
            const newCompanies = [...prevCompanies, ...companiesWithHRs];
            return [...new Map(newCompanies.map(company => [company._id, company])).values()]; // Ensures unique companies by _id
          });
      
          setfilteredData((prevCompanies) => {
            const newCompanies = [...prevCompanies, ...companiesWithHRs];
            return [...new Map(newCompanies.map(company => [company._id, company])).values()]; // Ensures unique companies by _id
          });
          setTotalHRs(total);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false);
        }
      };
      
      useEffect(() => {
        setData([]);
        setfilteredData([]);
      }, [searchTerm, filters]);
    
      useEffect(() => {
        getData();
        // // Clear the previous timeout, if any
        // if (timer.current) {
        //   clearTimeout(timer.current);
        // }

        // // Set a new timeout to update the search term after a delay (debouncing)
        // timer.current = setTimeout(() => {
        //   getData();
        // }, 1000); // 1000ms = 1 second delay
      }, [searchTerm, filters, page]);
    
      useEffect(() => {
        if (activeHR) {
          localStorage.setItem('activeHR', JSON.stringify(activeHR));
          localStorage.setItem('popupVisible', 'true');
        }
      }, [activeHR]);
    
      useEffect(() => {
        const savedHR = localStorage.getItem('activeHR');
        const savedPopupVisible = localStorage.getItem('popupVisible');
      
        if (savedHR && savedPopupVisible === 'true') {
          setActiveHR(JSON.parse(savedHR));
          setPopupVisible(true);
        }
      }, []);

      const timer = useRef(null); // Store the timer ID

      // Function to handle search input with debounce
      const handleChange = (e) => {
        // Clear the previous timeout, if any
        setSearchQuery(e.target.value);
        if (timer.current) {
          clearTimeout(timer.current);
        }

        // Set a new timeout to update the search term after a delay (debouncing)
        timer.current = setTimeout(() => {
          setSearchTerm(e.target.value); // Update the search term after 1 second delay
          setPage(1); // Reset the page to 1 on search change
        }, 1000); // 1000ms = 1 second delay
      };
    
      // Cleanup the timeout when the component unmounts
      useEffect(() => {
        return () => {
          if (timer.current) {
            clearTimeout(timer.current); // Clear the timeout on unmount to prevent memory leaks
          }
        };
      }, []);
    
  
      const userType = localStorage.getItem("userType");

  const toggleRow = (id) => {
    setOpen((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  };

  const handleOpenResponseDialog = (hr) => {
    setEditData(hr);
    setOpenResponseDialog(true); 
  };
  
  const handleCloseResponseDialog = () => {
    setEditData({});
    setOpenResponseDialog(false); 
  };

  const handleContactClick = (hrId, hrPhone, hrName, hrEmail, hrCompany, hrRes) => {
    if(!hrPhone) alert("Phone Not Available");
    const cleanedNumber = hrPhone.replace(/[^0-9]/g, ''); // Remove all non-digit characters
  
    if (cleanedNumber.length >= 10) {
      if (!hrEmail || hrEmail === '-' || hrEmail === 'NA'){
          setActiveHR({ hrId, hrName, hrCompany, hrPhone });
      } 
      else {
        setActiveHR({ hrId, hrName, hrEmail, hrCompany, hrPhone });
      }
      setHrResponse(hrRes || "");
      window.location.href = `tel:${cleanedNumber}`;
      setPopupVisible(true);
    } else {
      alert("Number not available");
    }
  };
  
  const handleEmailClick = (email) => {
    if (!email || email === '-' || email === 'NA') {
      alert('Email is not available');
      return;
    }
  
    window.location.href = `mailto:${email}`;
  };

  const handleHRResponse = (responded, hrResponse) => {
    if (responded && activeHR) {
      const updatedDate = new Date();
      // Update the LastContacted date for this HR in your backend or state
      handleDateChange(activeHR.hrId, updatedDate, hrResponse);
    }
    closeResponsePopup();
  };

  const closeResponsePopup = () => {
    setActiveHR(null);
    localStorage.removeItem('activeHR');
    localStorage.removeItem('popupVisible');
    setPopupVisible(false);
  };

  const handleDateChange = (HRId, date, response) => {
    if(!date){
      alert("Please Update Last Contacted First");
      return;
    }
    if(updatingLastContacted) return;
    if(updatingResponse) return;
    if(lastContactedPopupVisible){
      setLastContactedUpdatingHrId(HRId);
      setUpdatingLastContacted(true);
    }
    if(openResponseDialog){
      setResponseUpdatingHrId(HRId);
      setUpdatingResponse(true);
    }
    // Update lastContacted in the backend
    axios.put(`${baseURL}/contacted/${HRId}`, { date, response }, {
      headers: { authorization: localStorage.getItem("authToken") }
    })
      .then((res)=>{
        setData(data.map(company => {
          let newCompanyHRs = company.HRs.map((hr)=>hr._id===HRId? res.data : hr);
          return {...company, HRs: newCompanyHRs};
        }));
        setfilteredData(filteredData.map(company => {
          let newCompanyHRs = company.HRs.map((hr)=>hr._id===HRId? res.data : hr);
          return {...company, HRs: newCompanyHRs};
        }));
      })
      .catch(error => {
        console.error("There was an error updating the date!", error);
      })
      .finally(()=>{
        setUpdatingLastContacted(false);
        setLastContactedUpdatingHrId(null);
        setLastContactedPopupVisible(false);
        setUpdatingResponse(false);
        setResponseUpdatingHrId(null);
        setOpenResponseDialog(false);
      });
      
    setHrResponse("");
    setEditData({});
  };

  const handleEditChange = (event) => {
    const { name, value } = event.target;
    setEditData((prevData) => ({ ...prevData, [name]: value }));
  };

    return (
        <div className="app-table-container">
            {(
  <>
  <Paper className="app-paper-container">
    <TableContainer className="border-0">
      <MuiTable className="app-data-table" aria-label="simple table">
        <TableHead className="app-table-head">
          <TableRow className="app-table-row">
            <TableCell className="app-table-cell">
              <FiTable size={30}/>
            </TableCell>
            <TableCell className="app-table-cell !font-bold !text-lg">
              COMPANY
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData
            .map((row, index) => {
              return (
                <React.Fragment key={`row-${row._id}-${index}`}>
                  <TableRow ref={data.length === index + 1 ? lastElement : null} hover className="app-table-row">
                    <TableCell className="app-table-cell">
                      <IconButton onClick={() => toggleRow(row._id)}>
                        {open[row._id] ? <FaAngleDown /> : <FaAngleRight />}
                      </IconButton>
                    </TableCell>
                    <TableCell className="app-table-cell">{row.name}</TableCell>
                  </TableRow>
                  <TableRow className="app-table-row">
                    <TableCell
                      style={{padding: 0 }}
                      colSpan={5}
                    >
                      <Collapse
                        in={open[row._id]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <TableContainer className="app-name-table-container">
                          <MuiTable size="small" aria-label="hr details" className="app-name-table">
                            <TableBody>
                              {Array.isArray(row.HRs) &&
                                row.HRs.map((hr, hrIndex) => (
                                  <React.Fragment key={`hr-${hr._id}-${hrIndex}`}>
                                  <TableRow onClick={()=> toggleRow(hr._id)} className="app-name-table-row">
                                    <TableCell className="app-name-table-cell !pl-[88px]">{hr.name}</TableCell>
                                  </TableRow>
                                  <TableRow className="app-table-row">
                                  <TableCell
                                    style={{padding: 0 }}
                                    colSpan={5}
                                  >
                                    <Collapse
                                      in={open[hr._id]}
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      <TableContainer className="app-nested-table-container">
                                        <MuiTable size="small" aria-label="hr details" className="app-nested-table">
                                          <TableBody>
                                          <TableRow key={`nested-${hr._id}-${hrIndex}`} className="app-nested-table-row">
                                                  <TableCell className="app-nested-table-cell">
                                                    {
                                                      <div className='app-nested-table-cell-container'>
                                                      <button className="app-contact-button" onClick={() => handleContactClick(hr._id, hr.phone, hr.name, hr.email, row.name, hr.response)}>
                                                        <MdPhoneInTalk size={30} className="app-icon-style"/>
                                                        <div className="app-button-text">Phone</div>
                                                      </button>
                                                      </div>
                                                    }
                                                  </TableCell>
                                                  <TableCell className="app-nested-table-cell">
                                                    <div className='app-nested-table-cell-container'>
                                                    <button className="app-email-button" onClick={() => handleEmailClick(hr.email)}>
                                                      <IoMdMail size={30} className="app-icon-style"/>
                                                      <div className="app-button-text">Email</div>
                                                    </button>
                                                    </div>
                                                  </TableCell>
                                                  <TableCell className="app-nested-table-cell">
                                                    <div className='app-nested-table-cell-container'>
                                                  {hr.linkedIn ? (
                                                    <a
                                                      href={hr.linkedIn}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      className="app-linkedin-link"
                                                    >
                                                      <FaLinkedin size={30}/>
                                                      <div className="app-button-text">LinkedIn</div>
                                                    </a>
                                                    ) : (
                                                    <button className="app-linkedin-link" onClick={()=> alert("LinkedIn not Available")}>
                                                      <FaLinkedin size={30} className="app-icon-style"/>
                                                      <div className="app-button-text">LinkedIn</div>
                                                    </button>
                                                    )}
                                                    </div>
                                                  </TableCell>                                          
                                                  <TableCell className={`app-nested-table-cell`}>
                                                    <div className="app-nested-table-cell-container">
                                                  {((updatingLastContacted && hr._id===lastContactedUpdatingHrId) || (updatingResponse && hr._id===responseUpdatingHrId))? 
                                                      <CircularProgress size={30} />
                                                      :
                                                    <button className="app-more-info-button" onClick={() => handleOpenResponseDialog(hr)}>
                                                      <HiDotsCircleHorizontal size={30} /> 
                                                      <div className="app-button-text">More</div>
                                                    </button>}
                                                    </div>
                                                  </TableCell>
                                                </TableRow>
                                          </TableBody>
                                        </MuiTable>
                                      </TableContainer>
                                    </Collapse>
                                  </TableCell>
                                </TableRow>
                                </React.Fragment>
                                ))}
                            </TableBody>
                          </MuiTable>
                        </TableContainer>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}            
        </TableBody>
      </MuiTable>
    </TableContainer>
  </Paper>
  {hasMore && <div className="bg-transparent h-[100px] w-full text-center">{loading && <CircularProgress size={50}/>}</div>}
  <div className={`app-search-filter-dialog ${isFocused? "bottom-0": "bottom-14"}`}>
    <SearchInput 
    isFocused={isFocused} 
    setIsFocused={setIsFocused} 
    appliedFilters={filters}
    setFilters = {setFilters}  
    handleChange={handleChange}
    searchQuery={searchQuery}
    />
  </div>
  </>
)}

<Dialog open={popupVisible} onClose={closeResponsePopup} PaperProps={{ sx: { borderRadius: "1rem" } }}>
      <div className="app-inside-dialog">
    <DialogTitle className="app-edit-dialog-title !font-bold">Did the HR Respond?</DialogTitle>
    <hr className="app-contact-dialog-divider"/>
    <DialogContent>
      <Typography variant="body1" className="app-contact-dialog-text">
        <span className="app-edit-label !py-0">Name:</span> {activeHR?.hrName}
      </Typography>
      { activeHR?.hrEmail && 
      <Typography variant="body1" className="app-contact-dialog-text">
        <span className="app-edit-label !py-0">Email:</span> {activeHR?.hrEmail}
      </Typography>
      }
      <Typography variant="body1" className="app-contact-dialog-text">
        <span className="app-edit-label !py-0">Phone No:</span> {activeHR?.hrPhone}
      </Typography>
      <Typography variant="body1" className="app-contact-dialog-text">
        <span className="app-edit-label !py-0">Company:</span> {activeHR?.hrCompany}
      </Typography>
      <div className="app-edit-label-input-group">
      <label className="app-edit-label">Response:</label>
          <Autocomplete
            freeSolo
            options={hrResponses}
            groupBy={(option) => option.group}
            getOptionLabel={(option) => option.title}
            inputValue={hrResponse || ""}
            onInputChange={(event, newInputValue) => {
              setHrResponse(newInputValue);
            }}
            onChange={(event, value)=>{
              let newValue = (value?(value.title?(value.title.length>0? value.title: ""):""):"");
              setHrResponse(newValue);
            }}
            className="!p-0 app-edit-input app-add-company-input app-autocomplete-input"
            renderInput={(params) => 
              <TextField 
                {...params} 
                name="name"
                multiline
                maxRows={10}
                value={hrResponse || ""}
                onChange={(event, value)=>{
                  setHrResponse(event.target.value)
                }}
                fullWidth
                className="!w-[100%] !p-0 !border-0 app-autocomplete-input"
                InputProps={{
                  ...params.InputProps,
                  style: {
                    whiteSpace: 'pre-wrap', // Ensure the text wraps inside the input
                    wordWrap: 'break-word', // Break long words to fit the width
                  },
                }}
              />}
            renderGroup={(params) => (
              <li key={params.key}>
                <div className="app-autocomplete-group">{params.group}</div>
                <ul className="app-autocomplete-item">{params.children}</ul>
              </li>
            )}
            sx={{ width: 400 }}
          />
        </div>
      
    </DialogContent>
    <hr className="app-contact-dialog-divider"/>
    <DialogActions className='app-edit-dialog-actions'>
      <Button onClick={() => handleHRResponse(true, hrResponse)} className="app-edit-ok-button" color="primary">
        Yes
      </Button>
      <Button onClick={() => handleHRResponse(false, hrResponse)} className="app-edit-cancel-button" color="secondary">
        No
      </Button>
    </DialogActions>
    </div>
  </Dialog>
      <Dialog
        open={openResponseDialog}
        onClose={()=>{
          handleCloseResponseDialog()
        }}
        maxWidth="sm"
        fullWidth
        className="app-edit-dialog"
        PaperProps={{ sx: { borderRadius: "1.5rem" } }}
      >
        <div className="app-inside-dialog">
        <DialogTitle className="app-edit-dialog-title">
          <Edit/> More-Info
        </DialogTitle>
        <hr className="app-edit-dialog-divider"/>
        <DialogContent className="app-edit-dialog-content">
        <div className="app-edit-label-input-group">
          <label className="app-edit-label !w-[200px]">Last Contacted: </label>
          <span>{editData.lastContacted? editData.lastContacted : "Not Contacted"}</span>
        </div> 
        <div className="app-edit-label-input-group">
      <label className="app-edit-label">Response:</label>
          <Autocomplete
            freeSolo
            options={hrResponses}
            groupBy={(option) => option.group}
            getOptionLabel={(option) => option.title}
            inputValue={hrResponse || ""}
            onInputChange={(event, newInputValue) => {
              setHrResponse(newInputValue);
            }}
            onChange={(event, value)=>{
              let newValue = (value?(value.title?(value.title.length>0? value.title: ""):""):"");
              setHrResponse(newValue);
            }}
            className="!p-0 app-edit-input app-add-company-input app-autocomplete-input"
            renderInput={(params) => 
              <TextField 
                {...params} 
                name="name"
                multiline
                maxRows={10}
                value={hrResponse || ""}
                onChange={(event, value)=>{
                  setHrResponse(event.target.value)
                }}
                fullWidth
                className="!w-[100%] !p-0 !border-0 app-autocomplete-input"
                InputProps={{
                  ...params.InputProps,
                  style: {
                    whiteSpace: 'pre-wrap', // Ensure the text wraps inside the input
                    wordWrap: 'break-word', // Break long words to fit the width
                    border: 0
                  },
                }}
              />}
            renderGroup={(params) => (
              <li key={params.key}>
                <div className="app-autocomplete-group">{params.group}</div>
                <ul className="app-autocomplete-item">{params.children}</ul>
              </li>
            )}
            sx={{ width: 400 }}
          />
        </div>
      
        </DialogContent>
        <DialogActions className="app-edit-dialog-actions">
          <Button 
            onClick={()=>
              handleDateChange(editData._id, editData.lastContacted, editData.response)
            } 
            className="app-edit-ok-button" disabled={updatingResponse}>
          {
          updatingResponse?
            <CircularProgress size={10} />
            :"OK"
          }
          </Button>
          <Button onClick={()=>{
            handleCloseResponseDialog()
          }} className="app-edit-cancel-button" disabled={updatingResponse}>
            Cancel
          </Button>
        </DialogActions>
        </div>
      </Dialog>


        </div>
    );
};

export default MobileTable;
