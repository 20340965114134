import React, { useState  , useEffect} from "react";
import { FaLinkedinIn, FaWhatsapp, FaEnvelope, FaPhoneAlt, FaMapMarkerAlt } from "react-icons/fa";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Link } from '@mui/material';
import './Footer.css'; // Import the custom CSS file
import { NavLink } from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';

const Footer = () => {
  const [open, setOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(() => localStorage.getItem("theme") === "dark");
  const isMobile = useMediaQuery({query: '(max-width: 600px'});

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [isDarkMode]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <footer className="footer">
      {!isMobile && <div className="footer-container">
        <div className="footer-icons">
          <a href="https://www.linkedin.com/company/tnpgndec/" target="_blank" rel="noopener noreferrer" className="footer-icon"><FaLinkedinIn /></a>
          <a href="https://wa.me/9915794339" target="_blank" rel="noopener noreferrer" className="footer-icon whatsapp-icon"><FaWhatsapp /></a>
        </div>
        <div className="footer-contact">
          <h2>Contact Us</h2>
          <div className="footer-contact-info">
            <div className="contact-item">
              <FaEnvelope className="footer-sign" />
              <a href="mailto:tpo@gndec.ac.in" target="_blank" rel="noopener noreferrer" className="footer-link">tpo@gndec.ac.in</a>
            </div>
            <div className="contact-item">
              <FaPhoneAlt className="footer-sign" />
              <a href="tel:+919878859802" target="_blank" rel="noopener noreferrer" className="footer-link"><span className="whitespace-nowrap">+91 9878859802</span></a>
            </div>
            <div className="contact-item">
              <FaMapMarkerAlt className="footer-sign" />
              <span>Gill Road, Gill Park, Ludhiana, Punjab 141006</span>
            </div>
          </div>
        </div>
      </div>}

      {!isMobile && <hr className="footer-divider" />}

      <div className="footer-developed">
        <p>
          Developed with <span className="footer-heart"> ♥</span> by 
          <span 
            className="footer-link clickable ml-1"       
          >
           <NavLink to="/team">Genconians</NavLink> 
          </span> 
          <span style={{ marginRight: "5px" }}> | </span>
          <span>&copy;2024 
          <a href="https://www.gndec.ac.in" target="_blank" rel="noopener noreferrer" className="footer-link" style={{ marginLeft: "5px" }}>
            GNDEC,Ldh
          </a>
          </span>
        </p>
      </div>

      
    </footer>
  );
};

export default Footer;