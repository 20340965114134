import React from 'react';
import { FaMapMarkerAlt, FaLinkedin, FaWhatsapp } from 'react-icons/fa';
import './Contact.css';
import { MdPhoneInTalk } from "react-icons/md";
import { IoMdMail } from "react-icons/io";
import { NavLink } from 'react-router-dom';

const Contact = () => {
  return (
    <>
    <div className="contact-container">
      <h2 className="contact-heading">Contact Information</h2>
      <div className="contact-info">
        <div className="contact-item">
          <MdPhoneInTalk size={30} className="contact-icon" />
        </div>
        <div className="contact-item">
          <p className="contact-text">+91 9878859802</p>
        </div>
        <div className="contact-item">
          <IoMdMail size={30} className="contact-icon" />
        </div>
        <div className="contact-item">
          <p className="contact-text">tpo@gndec.ac.in</p>
        </div>
        <div className="contact-item">
          <FaMapMarkerAlt size={30} className="contact-icon" />
        </div>
        <div className="contact-item">
          <p className="contact-text">GNDEC, Gill Rd, Gill Park, Ludhiana, Punjab 141006</p>
        </div>
        <div className="contact-socials">
        <a href="https://www.linkedin.com/company/tnpgndec/" target="_blank" rel="noopener noreferrer" className="footer-icon">
          <FaLinkedin size={30} className="contact-social-icon mobile-linkedin-icon" />
        </a>
        <a href="https://wa.me/9915794339" target="_blank" rel="noopener noreferrer" className="footer-icon whatsapp-icon"><FaWhatsapp size={30}/></a>
        </div>
      </div>
    </div>
    <div>
  <div className="mobile-footer-developed">
    <p className="mobile-footer-text">
      Developed with <span className="mobile-footer-heart">❤️</span> by
      <span className="mobile-footer-link clickable ml-1">
        <NavLink to="/team">Genconians</NavLink>
      </span>
      <span className="mobile-footer-divider"> | </span>
      <span>&copy;2024
        <a href="https://www.gndec.ac.in" target="_blank" rel="noopener noreferrer" className="mobile-footer-link ml-1">
          GNDEC,Ldh
        </a>
      </span>
    </p>
  </div>
</div>

    </>
  );
};

export default Contact;
