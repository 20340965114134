import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Table from "./components/Table";
import ImportPage from "./components/ImportPage";
import Trash from './components/Trash';
import SignIn from "./components/SignIn";
import Team from "./components/Team/Team";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";
import Companies from './components/Companies';
import Footer from "./components/Footer/Footer";
import {useMediaQuery} from 'react-responsive';
import MobileNavbar from './components/MobileNavbar/MobileNavbar';
import Contact from "./components/Contact/Contact";
import MobileTable from "./components/MobileTable/MobileTable";

const App = () => {
  const [isFocused, setIsFocused] = useState(false);
  let userType = localStorage.getItem("userType");
  const [searchTerm, setSearchTerm] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("authToken")
  );
  const [isDarkMode, setIsDarkMode] = useState(
    () => localStorage.getItem("theme") === "dark"
  );
  const isMobile = useMediaQuery({query: '(max-width: 600px'});
  
  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setIsAuthenticated(true);
    }

    document.body.className = isDarkMode ? "dark-mode" : "";
  }, [isDarkMode]);

  const handleSignIn = () => {
    setIsAuthenticated(true);
    userType = localStorage.getItem("userType");
  };

  const handleSignOut = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userType");
    setIsAuthenticated(false);
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem("theme", newMode ? "dark" : "light");
      return newMode;
    });
  };

  return (
    <Router>
      <div className={`app ${isDarkMode ? "dark-mode" : ""}`}>
        {isAuthenticated ? (
          <>
            <div className="flex flex-col min-h-screen">
              <header className="sidebar">
                <Sidebar
                  onSignOut={handleSignOut}
                  toggleDarkMode={toggleDarkMode}
                  isDarkMode={isDarkMode}
                  onSearch={handleSearch}
                  type={localStorage.getItem("userType")}
                />
              </header>
              <main className={`main-content ${isFocused ? 'focused' : ''}`}>
                <Routes>
                  <Route
                    path="/"
                    element={
                      userType==="superadmin"?<div>
                        <h1 className = "mt-2">Welcome to TNP Research Work</h1>
                        <p>
                          Use the Navbar to navigate to the data table and other pages
                        </p>
                      </div>
                      : 
                      <Navigate to={"/table"}/> 
                    }
                  />
                  {isMobile?
                  <Route path="/table" element={<MobileTable isFocused={isFocused} setIsFocused={setIsFocused}/>} /> 
                  :
                  <Route
                    path="/table"
                    element={<Table searchTerm={searchTerm} />}
                  />}
                  <Route path="/import" element={<ImportPage />} />
                  <Route path="/trash" element={<Trash />} />
                  <Route path="/companies" element={<Companies />} />
                  <Route path="/team" element={<Team />} />
                  {isMobile && <Route path="/contact" element={<Contact/>} />}
                </Routes>
              </main>
              {!isMobile && <footer className="footer">
                <Footer />
              </footer>}
              {isMobile && 
                <div className="app-mobile-navbar">
                  <MobileNavbar
                    onSignOut={handleSignOut}
                    type={localStorage.getItem("userType")}
                  />
                </div>
              }
            </div>
          </>
        ) : (
          <SignIn onSignIn={handleSignIn} />
        )}
      </div>
    </Router>
  );
};

export default App;