import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { FaSun, FaMoon, FaChevronDown } from 'react-icons/fa';
import { MdLogout } from 'react-icons/md';
import './Sidebar.css';
import {useMediaQuery} from 'react-responsive';

const Sidebar = ({ toggleDarkMode, isDarkMode, onSignOut, type }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null); // Ref for dropdown
  const isMobile = useMediaQuery({query: '(max-width: 600px'});

  const handleToggleTheme = () => {
    const newMode = !isDarkMode;
    toggleDarkMode(newMode);
    document.body.classList.toggle('dark-mode', newMode);
    localStorage.setItem('theme', newMode ? 'dark' : 'light');
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const getRole = () => {
    if (type === 'superadmin') {
      return 'Super Admin';
    } else if (type === 'admin') {
      return 'Admin';
    } else if (type === 'executive') {
      return 'Executive';
    }
  };

  return (
    <div className='logo1'>
      {!isMobile && <div className="logo-section">
        <img src="favicon.ico" alt="Logo" className="logo" />
      </div>}
      <section className={`navbar ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
        {!isMobile && <ul className="nav-links">
          {(type === 'superadmin') && (
            <li>
              <NavLink
                to="/"
                className={({ isActive }) => 
                  `${isActive && 'active'} nav-link`
                }              
              >
                Home
              </NavLink>
            </li>
          )}
          <li>
            <NavLink
              to="/table"
              className={({ isActive }) => 
                `${isActive && 'active'} nav-link`
              }
            >
              Table
            </NavLink>
          </li>
          {(type === 'superadmin' || type === 'admin') && (
            <li>
              <NavLink
                to="/import"
                className={({ isActive }) => 
                  `${isActive && 'active'} nav-link`
                }
              >
                Import
              </NavLink>
            </li>
          )}
          {type === 'superadmin' && (
            <li>
              <NavLink
                to="/companies"
                className={({ isActive }) => 
                  `${isActive && 'active'} nav-link`
                }
              >
                Companies
              </NavLink>
            </li>
          )}
        </ul>}
        {isMobile && <div className="app-logo-section">
          <img src="favicon.ico" alt="Logo" className="app-logo" />
        </div>}
        <div className="user-section">
          <div className={`role-text ${isDarkMode ? 'text-white' : 'text-gray-600'}`}>{getRole()}</div>
          {!isMobile && <div className="dropdown" ref={dropdownRef}>
            <button className="dropdown-btn" onClick={handleDropdownToggle}>
              <FaChevronDown className="dropdown-icon" />
            </button>
            {isDropdownOpen && (
              <div className="dropdown-content">
                <button
                  className="signout-btn flex items-center bg-gray-100 p-2 rounded-lg  border-2 border-black"
                  onClick={onSignOut}
                >
                  <MdLogout className="signout-icon mr-2" /> {/* MdLogout icon */}
                  <span className='signout-text'>SIGN OUT</span>
                </button>
              </div>
            )}
          </div>}
          {!isMobile && <div className="theme-btn" onClick={handleToggleTheme}>
            {isDarkMode ? <FaMoon className="theme-icon" /> : <FaSun className="theme-icon" />}
          </div>}
          {isMobile && <div onClick={handleToggleTheme}>
            {isDarkMode ? <FaMoon className="theme-icon" /> : <FaSun className="theme-icon" />}
          </div>}
        </div>
      </section>
    </div>
  );
};

export default Sidebar;
