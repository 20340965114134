import React, { useState, useEffect } from 'react';
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem, Paper, Radio, RadioGroup, Select, Typography } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import './FilterComponent.css'

const FilterComponent = ({ branches, statuses, appliedFilters, onFilterChange, onClose, showBranches = true, showStatuses = true, showSorting = true }) => {
  const [selectedBranches, setSelectedBranches] = useState(appliedFilters.branches);
  const [selectedStatus, setSelectedStatus] = useState(appliedFilters.status);
  const [sortOrder, setSortOrder] = useState(appliedFilters.sortOrder);

  useEffect(() => {
    setSelectedBranches(appliedFilters.branches);
    setSelectedStatus(appliedFilters.status);
    setSortOrder(appliedFilters.sortOrder);
  }, [appliedFilters]);

  const handleBranchChange = (branch) => {
    setSelectedBranches((prev) =>
      prev.includes(branch)
        ? prev.filter((b) => b !== branch)
        : [...prev, branch]
    );
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  const applyFilters = () => {
    onFilterChange({ branches: selectedBranches, status: selectedStatus, sortOrder });
    onClose(); // Close the dialog after applying filters
  };

  const clearFilters = () => {
    setSelectedBranches([]);
    setSelectedStatus([]);
    setSortOrder(''); // Clear all filters
    onFilterChange({ branches: [], status: [], sortOrder: ''});
    onClose(); // Close the dialog after applying filters
  };

  return (
    <Paper className="filter-paper">
      <div className="pt-4 pl-4 pr-4">
        <Typography variant="h6" className="filter-title"><TuneIcon/> Apply Filters</Typography>
      </div>
      <div className="filter-hr-divider"/>
      <div className="pl-4 pr-4">
      {showBranches && 
      <>
      <Typography variant="h6" className="filter-text">Branches</Typography>
      <FormGroup row>
        {branches.map((branch) => (
          <FormControlLabel
            className="filter-text"
            key={branch}
            control={
              <Checkbox
                checked={selectedBranches.includes(branch)}
                onChange={() => handleBranchChange(branch)}
                className="filter-text"
              />
            }
            label={branch}
          />
        ))}
      </FormGroup>
      </>
      }

      {showStatuses && <>
      <Typography variant="h6" className="filter-text">Status</Typography>
      <FormControl fullWidth>
        <Select
          multiple
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
          renderValue={(selected) => selected.join(', ')}
          className="filter-select"
        >
          {Object.entries(statuses).map(([status, color]) => (
            <MenuItem key={status} value={status} className="filter-select-item">
              <Checkbox checked={selectedStatus.includes(status)} className="filter-text"/>
              <span
                style={{
                  backgroundColor: color,
                  borderRadius: "50%",
                  border: '0.2px solid black',
                  display: "inline-block",
                  width: "9px",
                  height: "9px",
                  marginRight: "8px",
                }}
              ></span>
              {status}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </>}

      {showSorting && <>
      <Typography variant="h6" className="filter-text">Apply Sort</Typography>
      <FormControl component="fieldset">
        <RadioGroup value={sortOrder} onChange={handleSortChange}>
          <FormControlLabel value="First Added" control={<Radio className="filter-text"/>} label="First Added" className="filter-text"/>
          <FormControlLabel value="Last Added" control={<Radio className="filter-text"/>} label="Last Added" className="filter-text"/>
          <FormControlLabel value="First Contacted" control={<Radio className="filter-text"/>} label="First Contacted" className="filter-text" />
          <FormControlLabel value="Last Contacted" control={<Radio className="filter-text"/>} label="Last Contacted" className="filter-text" />
          <FormControlLabel value="A to Z" control={<Radio className="filter-text"/>} label="A to Z" className="filter-text" />
          <FormControlLabel value="Z to A" control={<Radio className="filter-text"/>} label="Z to A" className="filter-text" />
        </RadioGroup>
      </FormControl>
      </>}
      </div>

      <div className="filter-hr-divider"/>
      <div className="pl-4 pr-4 pb-4">
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16}}>
      <Button variant="contained" onClick={clearFilters} className="mx-2  bg-white hover:bg-slate-100 text-black">
        Clear Filters
      </Button>
      <Button variant="contained" onClick={()=>onClose()} className="mx-2 bg-white hover:bg-slate-100 text-black">
        Cancel
      </Button>
      <Button variant="contained" onClick={applyFilters} className="mx-2 !bg-blue-500 hover:!bg-blue-600">
        Apply
      </Button>
      </div>
      </div>
    </Paper>
  );
};

export default FilterComponent;
