import React from 'react';
import { FaHome } from "react-icons/fa"; 
import { FiTable } from "react-icons/fi";
import { BiImport } from "react-icons/bi";
import { FiLogOut } from "react-icons/fi";
import './MobileNavbar.css';
import { NavLink } from 'react-router-dom';
import { MdInfoOutline } from "react-icons/md";
import { FaBuildingCircleArrowRight } from "react-icons/fa6";
import { BsPersonSquare } from "react-icons/bs";

function MobileNavbar({onSignOut, type}) {
    return (
        <nav className="mobile-navbar">
            <ul className="mobile-navbar-container">
                {type==="superadmin" && <li className="mobile-navbar-item">
                    <NavLink to="/" className={({ isActive }) => `${isActive && 'active'} mobile-navbar-link`}>
                        <FaHome className="mobile-navbar-icon" />
                    </NavLink>
                </li>}
                <li className="mobile-navbar-item">
                    <NavLink to="/table" className={({ isActive }) => `${isActive && 'active'} mobile-navbar-link`}>
                        <FiTable className="mobile-navbar-icon" />
                    </NavLink>
                </li>
                {(type==="admin" || type==="superadmin") && <li className="mobile-navbar-item">
                    <NavLink to="/import" className={({ isActive }) => `${isActive && 'active'} mobile-navbar-link`}>
                        <BiImport className="mobile-navbar-icon" />
                    </NavLink>
                </li>}
                <li className="mobile-navbar-item">
                    <NavLink to="/contact" className={({ isActive }) => `${isActive && 'active'} mobile-navbar-link`}>
                        <BsPersonSquare className="mobile-navbar-icon" />
                    </NavLink>
                </li>
                {type==="superadmin" && <li className="mobile-navbar-item">
                    <NavLink to="/companies" className={({ isActive }) => `${isActive && 'active'} mobile-navbar-link`}>
                        <FaBuildingCircleArrowRight className="mobile-navbar-icon" />
                    </NavLink>
                </li>}
                <li className="mobile-navbar-item">
                    <button onClick={onSignOut} className="mobile-navbar-link">
                        <FiLogOut className="mobile-navbar-icon" />
                    </button>
                </li>
            </ul>
        </nav>
    );
}

export default MobileNavbar;
