import React, { useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import './CustomPagination.css';
import { useMediaQuery } from 'react-responsive';

const CustomPagination = ({
  rowsPerPage,
  totalRows,
  currentPage,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const [jumpToPage, setJumpToPage] = useState('');
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const maxVisiblePages = 5;
  const isVeryBig = useMediaQuery({ query: '(max-width: 950px)'});
  const isBigEnough = useMediaQuery({ query: '(max-width: 750px)'});

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePageClick = (page) => {
    onPageChange(page);
  };

  const handleRowsPerPageChange = (event) => {
    onRowsPerPageChange(parseInt(event.target.value, 10));
  };

  const handleJumpToPageChange = (event) => {
    setJumpToPage(event.target.value);
  };

  const handleJumpToPageSubmit = () => {
    const pageNumber = parseInt(jumpToPage, 10) - 1;
    if (pageNumber >= 0 && pageNumber < totalPages) {
      onPageChange(pageNumber);
    } else {
      alert('Page number out of range');
    }
    setJumpToPage('');
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(0, currentPage - 2);
    const endPage = Math.min(totalPages - 1, currentPage + 2);

    if (totalPages <= maxVisiblePages) {
      for (let i = 0; i < totalPages; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => handlePageClick(i)}
            className={`page-number ${currentPage === i ? 'active' : ''}`}
          >
            {i + 1}
          </button>
        );
      }
    } else {
      if (currentPage > 3) {
        pageNumbers.push(
          <button key={0} onClick={() => handlePageClick(0)} className="page-number">
            1
          </button>,
          <span key="start-ellipsis" className="ellipsis">
            ...
          </span>
        );
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => handlePageClick(i)}
            className={`page-number ${currentPage === i ? 'active' : ''}`}
          >
            {i + 1}
          </button>
        );
      }

      if (currentPage < totalPages - 4) {
        pageNumbers.push(
          <span key="end-ellipsis" className="ellipsis">
            ...
          </span>,
          <button
            key={totalPages - 1}
            onClick={() => handlePageClick(totalPages - 1)}
            className="page-number"
          >
            {totalPages}
          </button>
        );
      }
    }

    return pageNumbers;
  };

  return (
    <div className="pagination-container">
      <div className="rows-per-page-container">
        <span>Rows per page:</span>
        <select
          value={rowsPerPage}
          onChange={handleRowsPerPageChange}
          className="rows-per-page-select"
        >
          <option value={2}>2</option>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={25}>25</option>
        </select>
        {!isBigEnough && <span>
          {`${Math.min((currentPage + 1) * rowsPerPage, totalRows)===0 ? 0 : currentPage * rowsPerPage + 1}-${Math.min((currentPage + 1) * rowsPerPage, totalRows)} of ${totalRows}`}
        </span>}
      </div>
  
      <div className="page-numbers-container">
        {renderPageNumbers()}
      </div>
  
      <div className="nav-buttons-container">
        <button
          onClick={handlePreviousPage}
          className="nav-button previous"
          disabled={currentPage === 0}
        >
          <FaArrowLeft className="icon" /> {!isBigEnough && <span>Previous</span>}
        </button>
        <button
          onClick={handleNextPage}
          className="nav-button next"
          disabled={currentPage >= totalPages - 1}
        >
          {!isBigEnough && <span>Next</span>} <FaArrowRight className="icon pl-1"/>
        </button>
      </div>
  
      {!isVeryBig && <div className="jump-to-page-container">
        <button onClick={handleJumpToPageSubmit} className="jump-to-page-button">
          GoTo
        </button>
        <input
          type="number"
          value={jumpToPage}
          onChange={handleJumpToPageChange}
          placeholder={`${currentPage+1}`}
          min="1"
          max={totalPages}
          className="jump-to-page-input"
        />
      </div>}
    </div>
  );  
};

export default CustomPagination;
