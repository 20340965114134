import React, { useState, useRef, useEffect } from 'react';
import { FaFilter } from 'react-icons/fa';  // Filter icon
import { IoSearchSharp } from "react-icons/io5";
import './SearchInput.css';  // Your custom CSS file

function SearchWithFilter({ isFocused, setIsFocused, appliedFilters, setFilters, handleChange, searchQuery}) {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const containerRef = useRef(null);  // Ref for the entire search/filter container
  const [selectedBranches, setSelectedBranches] = useState(appliedFilters.branches);
  const [selectedStatus, setSelectedStatus] = useState(appliedFilters.status);
  const [sortOrder, setSortOrder] = useState(appliedFilters.sortOrder);

  // Handle clicks outside the component to close the filter section
  useEffect(() => {
    setSelectedBranches(appliedFilters.branches);
    setSelectedStatus(appliedFilters.status);
    setSortOrder(appliedFilters.sortOrder);
  }, [appliedFilters]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsFilterOpen(false);  // Close the filter when clicking outside
        setIsFocused(false);     // Remove focus if clicked outside
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setIsFocused]);

  const onClose = () => {
    setIsFilterOpen(false);
  }

  const handleBranchChange = (branch) => {
    setSelectedBranches((prev) =>
      prev.includes(branch)
        ? prev.filter((b) => b !== branch)
        : [...prev, branch]
    );
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  const applyFilters = () => {
    setFilters({ branches: selectedBranches, status: selectedStatus, sortOrder });
    onClose(); // Close the dialog after applying filters
  };

  const clearFilters = () => {
    setSelectedBranches([]);
    setSelectedStatus([]);
    setSortOrder(''); // Clear all filters
    setFilters({ branches: [], status: [], sortOrder: ''});
    onClose(); // Close the dialog after applying filters
  };

  const handleFilterButtonClick = () => {
    setIsFilterOpen(!isFilterOpen);  // Toggle the filter options
    setIsFocused(true);              // Ensure the search container stays focused when clicked
  };

  const handleInputFocus = () => {
    setIsFocused(true);  // Set focus when the input is clicked
    setIsFilterOpen(false);
  };

  const handleInputBlur = () => {
    // Optional: You can handle blur events here if needed
  };

  return (
    <div
      ref={containerRef}
      className={`search-filter-wrapper ${isFocused ? 'focused' : ''}`}  // Apply focus styles
    >
      <div className="search-filter-container">
        <FaFilter
          size={20}
          className="filter-icon"
          onClick={handleFilterButtonClick}  // Toggle filter on click
        />
        <input
          type="text"
          placeholder="Search Here..."
          className="search-input"
          value={searchQuery}
          onChange={(e)=>handleChange(e)}
          onFocus={handleInputFocus}   // Set focus when input is clicked
          onBlur={handleInputBlur}     // Optional: Handle blur
        />
        <IoSearchSharp size={30} className="search-icon" />
      </div>

      {isFilterOpen && (
  <div className="filter-section">
    <h3 className='font-bold'>Apply Sort</h3>

    {/* Radio buttons for sorting */}
    <div>
      <input 
        type="radio" 
        name="sort" 
        id="lastAdded" 
        value="lastAdded" 
        checked={sortOrder === "lastAdded"} 
        onChange={handleSortChange} 
      />
      <label htmlFor="lastAdded">Last Added</label>
    </div>

    <div>
      <input 
        type="radio" 
        name="sort" 
        id="firstAdded" 
        value="firstAdded" 
        checked={sortOrder === "firstAdded"} 
        onChange={handleSortChange} 
      />
      <label htmlFor="firstAdded">First Added</label>
    </div>

    <div>
      <input 
        type="radio" 
        name="sort" 
        id="aToZ" 
        value="AtoZ" 
        checked={sortOrder === "AtoZ"} 
        onChange={handleSortChange} 
      />
      <label htmlFor="aToZ">A to Z</label>
    </div>

    <div>
      <input 
        type="radio" 
        name="sort" 
        id="zToA" 
        value="ZtoA" 
        checked={sortOrder === "ZtoA"} 
        onChange={handleSortChange} 
      />
      <label htmlFor="zToA">Z to A</label>
    </div>

    {/* Buttons for clearing and applying filters */}
    <div className="filter-buttons">
      <button 
        className="clear-filters" 
        onClick={clearFilters}
      >
        CLEAR FILTERS
      </button>

      <button 
        className="apply-filters" 
        onClick={applyFilters}
      >
        APPLY
      </button>
    </div>
  </div>
)}

    </div>
  );
}

export default SearchWithFilter;
