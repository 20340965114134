import React from "react";
import './TeamCard.css';
import { FaLinkedin } from "react-icons/fa6";
import { FaGithub } from "react-icons/fa";

const Card = ({ imageSrc, name, position, education, linkedIn, github }) => {
  return (
    
    <div className="user-card">
      <div className="card-image-container">
        <img src={imageSrc} alt={name} className="card-image" />
      </div>

      {/* Text Information */}
      <div className="card-info">
        <h2 className="card-name">{name}</h2>
        <p className="card-position">{position}</p>
        <p className="card-education">{education}</p>

        {/* Social Icons */}
        <div className="card-icons">
          <a href={linkedIn} target="_blank" rel="noopener noreferrer" className="icon-link">
            <FaLinkedin/>
          </a>
          <a href={github} target="_blank" rel="noopener noreferrer" className="icon-link">
            <FaGithub/>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Card;