// React Import
import React, { useState, useEffect } from "react";

import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
// MUI Imports
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  TableSortLabel,
  CircularProgress,
  IconButton,
  Collapse,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Autocomplete,
  Typography
} from "@mui/material";
import {
  DeleteOutline,
  FileCopy,
  Add,
  Edit,
  ExpandMore,
  ExpandLess
} from "@mui/icons-material";
import TuneIcon from '@mui/icons-material/Tune';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import SortIcon from '@mui/icons-material/Sort';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// CSS Import
import "./Table.css";
// Import From Other Files
import { baseURL } from "../baseURL.js";
import CustomDialog from "./CustomDialog/CustomDialog.js";
import CustomPagination from './Pagination/CustomPagination.js'
import DeleteConfirmation from "./Confirm.jsx";
import FilterComponent from './FilterComponent.jsx';
// Import Other Functionalities
import axios from "axios";
import { Link } from "react-router-dom";
import Fuse from "fuse.js";
import { sortBy } from "lodash";
import { useMediaQuery } from "react-responsive";

import {  FaEnvelope } from 'react-icons/fa';
import { MdPhoneInTalk } from "react-icons/md";
import { IoEye } from "react-icons/io5";
import { ImInfo } from "react-icons/im";

const Table = () => {
  const [data, setData] = useState([]);
  const [filteredData, setfilteredData] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("company");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [open, setOpen] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [modalType, setModalType] = useState(""); // "company" or "hr"
  const [editData, setEditData] = useState({});
  const [showModal, setShowModal] = useState(false); // State for showing the modal
  const [deleteInfo, setDeleteInfo] = useState({}); // State to store delete info (id and type)
  const [totalHRs, setTotalHRs] = useState(0);
  const [companyToDelete, setCompanyToDelete] = useState(null);
  const [showCompanyModal, setShowCompanyModal] = useState(false); // Separate state for company modal
  const [isAddNewDialogoOpen, setIsAddNewDialogoOpen] = useState(false);
  const [noValidEmail, showNoValidEmail] = useState(false);
  const [noValidPhone, showNoValidPhone] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [activeHR, setActiveHR] = useState(null); 
  const [updatingDetails, setUpdatingDetails] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filters, setFilters] = useState({
    branches: [],
    status: [],
    sortOrder: '',
  });
  const [lastContactedPopupVisible, setLastContactedPopupVisible] = useState(false);
  const [updatingLastContacted, setUpdatingLastContacted] = useState(false);
  const [lastContactedUpdatingHrId, setLastContactedUpdatingHrId] = useState(null);
  const [hrResponse, setHrResponse] = useState("");

  const [openResponseDialog, setOpenResponseDialog] = useState(false); // State to manage dialog open/close
  const [updatingResponse, setUpdatingResponse] = useState(false);
  const [responseUpdatingHrId, setResponseUpdatingHrId] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const statuses = ["In Progress", "Cancelled", "Awaiting Confirmation", "On hold", "Completed"];
  const branchesList = ["CSE", "IT", "ME", "EE", "ECE", "CE"];
  const isBigEnough = useMediaQuery({query: '(max-width: 700px)'});

  const hrResponses = [
    // Try Later
    { group: 'Try Later', title: 'Asked to Call Later' },
    { group: 'Try Later', title: 'Busy' },
    { group: 'Try Later', title: 'Call Declined' },
    { group: 'Try Later', title: 'Call Didn\'t Connect' },
    { group: 'Try Later', title: 'Could Not Hear' },
    { group: 'Try Later', title: 'Network Issue' },
    { group: 'Try Later', title: 'Number Not Reachable' },
    { group: 'Try Later', title: 'Switched Off' },
    { group: 'Try Later', title: 'Will Call Back soon' },
  
    // Invalid
    { group: 'Invalid', title: 'Invalid Number' },
    { group: 'Invalid', title: 'Number Not Exist' },
    { group: 'Invalid', title: 'Number Out of Service' },
    { group: 'Invalid', title: 'Person Not in that Company' },
    { group: 'Invalid', title: 'Person Not Handling Recruitments' },
    { group: 'Invalid', title: 'Wrong Person' },
  
    // Negative Response
    { group: 'Negative Response', title: 'Done with Hiring' },
    { group: 'Negative Response', title: 'Hiring Only Experienced Persons' },
    { group: 'Negative Response', title: 'No Positions Available' },
    { group: 'Negative Response', title: 'Not Currently Hiring' },
    { group: 'Negative Response', title: 'Not Doing Placements This Year' },
    { group: 'Negative Response', title: 'Not Hiring Freshers' },
    { group: 'Negative Response', title: 'Not Interested for Anything' },
    { group: 'Negative Response', title: 'Not Interested in Campus Hiring' },
  
    // Positive Response
    { group: 'Positive Response', title: 'Email Provided for sharing Details' },
    { group: 'Positive Response', title: 'Shared Recruitment Team Email' },
    { group: 'Positive Response', title: 'Shared TPO Details, will contact us' },
    { group: 'Positive Response', title: 'Will Message Details' },
  
    // Interested In
    { group: 'Interested In', title: 'Interested in Placements' },
    { group: 'Interested In', title: 'Interested in Internships' },
    { group: 'Interested In', title: 'Interested in Training' },
    { group: 'Interested In', title: 'Interested in Conducting PPT' },
  
    // Other
    { group: 'Other', title: 'Not Now, but will Inform if Hiring Needed' },
    { group: 'Other', title: 'Will Ask HR to provide details or not' },
    { group: 'Other', title: 'Will Check and Inform' },
    { group: 'Other', title: 'Will Convey Message to HR' },
  ];
  
  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(baseURL + "/get-data", {
        headers: {
          authorization: localStorage.getItem("authToken"),
        },
        withCredentials: true,
      });

      let companiesWithHRs = Object.values(response.data).filter(
        (company) => company.HRs && company.HRs.length > 0
      );

      let total = 0;
      for (let i = 0; i < companiesWithHRs.length; i++) {
        total += companiesWithHRs[i].HRs.length;
      }
      if (filters.sortOrder) {
        companiesWithHRs = companiesWithHRs.sort((a, b) => {
          const dateA = new Date(a.lastContacted);
          const dateB = new Date(b.lastContacted);
          const createDateA = new Date(a.createdAt);
          const createDateB = new Date(b.createdAt);
    
          if (filters.sortOrder === 'Last Contacted') {
            // Sort valid dates first, followed by invalid dates
            if (!isNaN(dateB) && isNaN(dateA)) return 1;
            if (!isNaN(dateA) && isNaN(dateB)) return -1;
            return dateB - dateA;
          } else if (filters.sortOrder === 'First Contacted') {
            // Sort valid dates first, followed by invalid dates
            if (!isNaN(dateB) && isNaN(dateA)) return 1;
            if (!isNaN(dateA) && isNaN(dateB)) return -1;
            return dateA - dateB;
          } else if (filters.sortOrder === 'Last Added') {
            // Sort valid dates first, followed by invalid dates
            if (!isNaN(createDateB) && isNaN(createDateA)) return 1;
            if (!isNaN(createDateA) && isNaN(createDateB)) return -1;
            return createDateB - createDateA;
          } else if (filters.sortOrder === 'First Added') {
            // Sort valid dates first, followed by invalid dates
            if (!isNaN(createDateB) && isNaN(createDateA)) return 1;
            if (!isNaN(createDateA) && isNaN(createDateB)) return -1;
            return createDateA - createDateB;
          } else if (filters.sortOrder === 'A to Z') {
            return a.name.localeCompare(b.name);
          } else if (filters.sortOrder === 'Z to A') {
            return b.name.localeCompare(a.name);
          }
          return 0;
        });
      }

      setData(companiesWithHRs);
      setfilteredData(companiesWithHRs);
      setTotalHRs(total);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(()=>{
    handleFilterChange(filters);
  }, [filters, data]);

  // Search Perform
  useEffect(() => {
    const performSearch = () => {
      if (searchTerm) {
        const fuse = new Fuse(data, {
          keys: ["name", "HRs.name", "HRs.phone", "HRs.email", "HRs.response"],
          includeScore: true,
          threshold: 0.3,
        });
  
        let result = fuse.search(searchTerm).map((result) => result.item);
        if (filters.sortOrder) {
          result = result.sort((a, b) => {
            const dateA = new Date(a.lastContacted);
            const dateB = new Date(b.lastContacted);
            const createDateA = new Date(a.createdAt);
            const createDateB = new Date(b.createdAt);
      
            if (filters.sortOrder === 'Last Contacted') {
              // Sort valid dates first, followed by invalid dates
              if (!isNaN(dateB) && isNaN(dateA)) return 1;
              if (!isNaN(dateA) && isNaN(dateB)) return -1;
              return dateB - dateA;
            } else if (filters.sortOrder === 'First Contacted') {
              // Sort valid dates first, followed by invalid dates
              if (!isNaN(dateB) && isNaN(dateA)) return 1;
              if (!isNaN(dateA) && isNaN(dateB)) return -1;
              return dateA - dateB;
            } else if (filters.sortOrder === 'Last Added') {
              // Sort valid dates first, followed by invalid dates
              if (!isNaN(createDateB) && isNaN(createDateA)) return 1;
              if (!isNaN(createDateA) && isNaN(createDateB)) return -1;
              return createDateB - createDateA;
            } else if (filters.sortOrder === 'First Added') {
              // Sort valid dates first, followed by invalid dates
              if (!isNaN(createDateB) && isNaN(createDateA)) return 1;
              if (!isNaN(createDateA) && isNaN(createDateB)) return -1;
              return createDateA - createDateB;
            } else if (filters.sortOrder === 'A to Z') {
              return a.name.localeCompare(b.name);
            } else if (filters.sortOrder === 'Z to A') {
              return b.name.localeCompare(a.name);
            }
            return 0;
          });
        }
        setfilteredData(result);
      } else {
        let result = data;
        setfilteredData(result);
      }
      setPage(0); // Reset page when search changes
    };
  
    performSearch();
  }, [searchTerm, data]); // Ensure both searchTerm and data are dependencies

  useEffect(() => {
    if (activeHR) {
      localStorage.setItem('activeHR', JSON.stringify(activeHR));
      localStorage.setItem('popupVisible', 'true');
    }
  }, [activeHR]);

  useEffect(() => {
    const savedHR = localStorage.getItem('activeHR');
    const savedPopupVisible = localStorage.getItem('popupVisible');
  
    if (savedHR && savedPopupVisible === 'true') {
      setActiveHR(JSON.parse(savedHR));
      setPopupVisible(true);
    }
  }, []);
  
  // useEffect(() => {
  //   if (searchTerm) {
  //     const fuse = new Fuse(data, {
  //       keys: ["name", "Hrs.name", "HRs.phone", "HRs.email"],
  //       includeScore: true,
  //       threshold: 0.3,
  //     });

  //     const result = fuse.search(searchTerm).map((result) => result.item);
  //     setPage(0);
  //     setData(result);
  //   } else {
  //     getData();
  //     setPage(0);
  //   }
  // }, [searchTerm]);

  const sortCompanyData = () => {
    setfilteredData(sortBy(filteredData, ["name"]));
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.HRs.map((hr) => hr._id));

      let newArr = [];
      for (var i = 0; i < newSelected.length; i++) {
        newArr = newArr.concat(newSelected[i]);
      }

      setSelected(newArr);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleSelectCompanyHRs = (event, company) => {
    if (event.target.checked) {
      const alreadySelected = selected.filter(
        (id) => !company.HRs.some((hr) => hr._id === id)
      );
      const newSelected = company.HRs.map((hr) => hr._id);
      setSelected((prevSelected) => [...alreadySelected, ...newSelected]);
    } else {
      const newSelected = selected.filter(
        (id) => !company.HRs.some((hr) => hr._id === id)
      );
      setSelected(newSelected);
    }
  };  

  const isSelected = (id) => selected.indexOf(id) !== -1;
  
  const handleDelete = (currSelected) => {
    setDeleteInfo([...currSelected]);
    setShowModal(true);
  };

  const confirmModal = async (toDelete, userRole) => {
    setDeleting(true);
    setLoading(true);
    try {
      await axios.delete(baseURL + `/delete-hrs`, {
        headers: {
          authorization: localStorage.getItem("authToken"),
          role: userRole,
        },
        withCredentials: true,
        data: {
          ids: toDelete,
        },
      });
      setSelected([]);
      getData();
    } catch (error) {
      console.error("Error deleting row:", error);
    } finally {
      setDeleting(false);
      setShowModal(false);
      setLoading(false);
    }
  };

  const handleDeleteCompany = async (companyId) => {
    try {
      await axios.delete(`${baseURL}/company/${companyId}`, {
        headers: {
          authorization: localStorage.getItem("authToken"),
        },
        withCredentials: true,
      });
      getData(); // Refresh the data after deletion
    } catch (error) {
      console.error("Error deleting company:", error);
    } finally {
      setShowCompanyModal(false);
    }
  };

  const confirmDeleteCompany = (companyId) => {
    setCompanyToDelete(companyId);
    setShowCompanyModal(true);
  };

  const addNewCompany = () => {
    setIsAddNewDialogoOpen(true);
  };

  const downloadData = () => {
    if (selected.length === 0) {
      axios
        .get(baseURL + "/export-data/", {
          headers: {
            authorization: localStorage.getItem("authToken"),
          },
          withCredentials: true,
        })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "data.csv");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.error("Error exporting data:", error);
        });
    } else {
      const currData = {
        ids: [...selected],
      }
      axios
        .post(baseURL + "/export-data/", currData, {
          headers: {
            authorization: localStorage.getItem("authToken"),
          },
          withCredentials: true
        })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "data.csv");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.error("Error exporting data:", error);
        });
    }
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
  };

  const paginatedData = data.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredData.length) : 0;

  const userType = localStorage.getItem("userType");

  const toggleRow = (id) => {
    setOpen((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  };

  const handleOpenModal = (item, type) => {
    setModalType(type);
    setEditData(item);
    setIsDialogOpen(true);
  };

  const handleCloseModal = () => {
    setIsDialogOpen(false);
    setEditData({});
    setErrorMessage(" ");
  };

  const handleOpenResponseDialog = (hr) => {
    setEditData(hr);
    setOpenResponseDialog(true); 
  };
  
  const handleCloseResponseDialog = () => {
    setEditData({});
    setOpenResponseDialog(false); 
  };
  
  const handleEditChange = (event) => {
    const { name, value } = event.target;
    if (name === "phone") {
      const numericValue = value.replace(/[^0-9]/g, ""); // Keep only numeric values
        if (numericValue.length <= 10) {
        setEditData((prevData) => ({ ...prevData, [name]: numericValue }));
      }
    } 
    //else if (name === "email") {
    //   // Basic email validation
    //   const isValidEmail = value ==="" || value.includes("@") && value.endsWith(".com");
    //   if (isValidEmail || value === "") { // Allow empty value if email is not yet entered
    //     setEditData((prevData) => ({ ...prevData, [name]: value }));
    //   }
    // } 
  
     else {
      setEditData((prevData) => ({ ...prevData, [name]: value }));
     }
  };

  const handleContactClick = (hrId, hrPhone, hrName, hrEmail, hrCompany, hrRes) => {
    const cleanedNumber = hrPhone.replace(/[^0-9]/g, ''); // Remove all non-digit characters
  
    if (cleanedNumber.length >= 10) {
      if (!hrEmail || hrEmail === '-' || hrEmail === 'NA'){
          setActiveHR({ hrId, hrName, hrCompany, hrPhone });
      } 
      else {
        setActiveHR({ hrId, hrName, hrEmail, hrCompany, hrPhone });
      }
      setHrResponse(hrRes || "");
      window.location.href = `tel:${cleanedNumber}`;
      setPopupVisible(true);
    } else {
      alert("Number not available");
    }
  };
  
  const handleEmailClick = (email) => {
    if (!email || email === '-' || email === 'NA') {
      alert('Email is not available');
      return;
    }
  
    window.location.href = `mailto:${email}`;
  };

  const handleHRResponse = (responded, hrResponse) => {
    if (responded && activeHR) {
      const updatedDate = new Date();
      // Update the LastContacted date for this HR in your backend or state
      handleDateChange(activeHR.hrId, updatedDate, hrResponse);
    }
    closeResponsePopup();
  };

  const closeResponsePopup = () => {
    setActiveHR(null);
    localStorage.removeItem('activeHR');
    localStorage.removeItem('popupVisible');
    setPopupVisible(false);
  };

  // const updateLastContacted = (companyId, hrId, updatedDate) => {
  //   // Your backend API call to update the HR's LastContacted date
  //   // or state update logic here.  
  //   try {
  //     await axios.put(`/api/hr/${hrId}`, { lastContacted: updatedDate });
  //     // Update your local state or trigger a re-fetch if needed
  //   } catch (error) {
  //     console.error('Error updating LastContacted:', error);
  //   }
  // };
  
  const handleBlur = (event) => {
    const { name, value } = event.target;
  
    if (name === "email") {
      const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      const isValid = value === "" || isValidEmail.test(value);
      showNoValidEmail(!isValid);
    }else if (name === "phone") {
      const isValidPhonePattern = /^[0-9]{10}$/;
      const isValid = value === "" || isValidPhonePattern.test(value);
      showNoValidPhone(!isValid);
    }
  };

  const handleDateChange = (HRId, date, response) => {
    if(!date){
      alert("Please Update Last Contacted First");
      return;
    }
    if(updatingLastContacted) return;
    if(updatingResponse) return;
    if(lastContactedPopupVisible){
      setLastContactedUpdatingHrId(HRId);
      setUpdatingLastContacted(true);
    }
    if(openResponseDialog){
      setResponseUpdatingHrId(HRId);
      setUpdatingResponse(true);
    }
    // Update lastContacted in the backend
    axios.put(`${baseURL}/contacted/${HRId}`, { date, response }, {
      headers: { authorization: localStorage.getItem("authToken") }
    })
      .then((res)=>{
        setData(data.map(company => {
          let newCompanyHRs = company.HRs.map((hr)=>hr._id===HRId? res.data : hr);
          return {...company, HRs: newCompanyHRs};
        }));
        setfilteredData(filteredData.map(company => {
          let newCompanyHRs = company.HRs.map((hr)=>hr._id===HRId? res.data : hr);
          return {...company, HRs: newCompanyHRs};
        }));
      })
      .catch(error => {
        console.error("There was an error updating the date!", error);
      })
      .finally(()=>{
        setUpdatingLastContacted(false);
        setLastContactedUpdatingHrId(null);
        setLastContactedPopupVisible(false);
        setUpdatingResponse(false);
        setResponseUpdatingHrId(null);
        setOpenResponseDialog(false);
      });
      
    setHrResponse("");
    setEditData({});
  };

   // const handleRowsPerPageChange = (newRowsPerPage) => {

  //   setRowsPerPage(newRowsPerPage);

  //   setCurrentPage(0); // Optionally reset to the first page

  // };

  const handleFilterChange = (filters) => {
    setFilters(filters);
    let filtered = [...filteredData]; // assuming `data` is your original dataset

    if (filters.sortOrder) {
      filtered = filtered.sort((a, b) => {
        const dateA = new Date(a.lastContacted);
        const dateB = new Date(b.lastContacted);
        const createDateA = new Date(a.createdAt);
        const createDateB = new Date(b.createdAt);
  
        if (filters.sortOrder === 'Last Contacted') {
          // Sort valid dates first, followed by invalid dates
          if (!isNaN(dateB) && isNaN(dateA)) return 1;
          if (!isNaN(dateA) && isNaN(dateB)) return -1;
          return dateB - dateA;
        } else if (filters.sortOrder === 'First Contacted') {
          // Sort valid dates first, followed by invalid dates
          if (!isNaN(dateB) && isNaN(dateA)) return 1;
          if (!isNaN(dateA) && isNaN(dateB)) return -1;
          return dateA - dateB;
        } else if (filters.sortOrder === 'Last Added') {
          // Sort valid dates first, followed by invalid dates
          if (!isNaN(createDateB) && isNaN(createDateA)) return 1;
          if (!isNaN(createDateA) && isNaN(createDateB)) return -1;
          return createDateB - createDateA;
        } else if (filters.sortOrder === 'First Added') {
          // Sort valid dates first, followed by invalid dates
          if (!isNaN(createDateB) && isNaN(createDateA)) return 1;
          if (!isNaN(createDateA) && isNaN(createDateB)) return -1;
          return createDateA - createDateB;
        } else if (filters.sortOrder === 'A to Z') {
          return a.name.localeCompare(b.name);
        } else if (filters.sortOrder === 'Z to A') {
          return b.name.localeCompare(a.name);
        }
        return 0;
      });
    }

    setfilteredData(filtered); // assuming `setfilteredData` updates the displayed data in your table
  };

  const handleSaveChanges = async () => {
    if(!editData) return;
    if(updatingDetails){
      return;
    }
    setUpdatingDetails(true);
    setErrorMessage("");
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const email = editData.email || "";
    const isValid = email === "" || isValidEmail.test(email);
    
    const isValidPhone = /^[0-9]{10}$/; // Assuming a 10-digit phone number
    const phone = editData.phone || "";
    const isPhoneValid = phone === "" || isValidPhone.test(phone);

    if (!isValid) {
      setUpdatingDetails(false);
      showNoValidEmail(true); // Show error message if email is invalid
      return; // Prevent saving changes
    }

    if (!isPhoneValid) {
      setUpdatingDetails(false);
      showNoValidPhone(true); // Show error message if email is invalid
      return; // Prevent saving changes
    }
    if (!isValid || !isPhoneValid) {
      setUpdatingDetails(false);
      return;
    }

    try {
      let response;
      // Update HR or Company depending on the modal type
      if (modalType === "company") {
        response = await axios.put(`${baseURL}/company/${editData._id}`, editData, {
          headers: { authorization: localStorage.getItem("authToken") },
          withCredentials: true,
        });
      } else if (modalType === "hr") {
        response = await axios.put(`${baseURL}/hr/${editData._id}`, editData, {
          headers: { authorization: localStorage.getItem("authToken") },
          withCredentials: true,
        });
      }

      // Handle successful response
      getData(); // Fetch updated data after save
      handleCloseModal(); // Close modal on success
      setErrorMessage(''); // Clear error message on success
    } catch (err) {
      // Handle backend errors
      const { response } = err;
      if (response && response.status === 400) {
        setErrorMessage(response.data.message); // Set error message from backend
      } else {
        setErrorMessage("An unexpected error occurred."); // Handle any other errors
      }
    } finally {
      setUpdatingDetails(false); // Reset updating state
    }
  };

  return (
    <div className="table-container">
    <CustomDialog
      isOpen={isAddNewDialogoOpen}
      setIsOpen={setIsAddNewDialogoOpen}
      getData={getData}
      type='hr'
    />
      <div className="table-controls">
      <input
        placeholder="Search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-bar"
      />
        <div className="controls-right">
          {(userType === "admin" || userType === "superadmin") && (
            <>
            <button className="table-btn export-button" onClick={downloadData}>
              <FileDownloadIcon />
              <span>Export</span>
            </button>
            {userType === "superadmin" && (
              <Link to="/trash" className="no-underline">
                <button className="table-btn trash-button">
                  <DeleteSweepIcon />
                  <span>Trash</span>
                </button>
              </Link>
            )}
            </>
          )}  
            {/* <button onClick={sortCompanyData} className="table-btn sorter-button">
              <SortIcon />
              <span>Sort Data</span>
            </button> */}
            <button
              className = "table-btn filter-button"
              variant="contained"
              color="primary"
              onClick={() => setFilterOpen(true)}
            >
              <TuneIcon/>
              Filter
            </button>
            {(userType === "superadmin" || userType === "admin") && (
              <>
              <button className="table-btn add-button" onClick={addNewCompany}>
                <AddCircleOutlineIcon />
                Add {!isBigEnough && <span>New</span>}
              </button>
              <button
              className="deletion-button"
              onClick={() => handleDelete(selected)}
              disabled={deleting || selected.length === 0}
            >
              {deleting ? (
                <CircularProgress size={20} />
              ) : (
                <>
                  <DeleteOutline />
                  <span>Delete</span>
                </>
              )}
            </button>
            </>
            )}
        </div>
      </div>
      {loading ? (
  <div className="loading-container">
    <CircularProgress />
  </div>
) : (
  <Paper className="paper-container" sx={{border:2, borderRadius:'0.5rem'}}>
    <TableContainer className="border-0 rounded-t-lg">
      <MuiTable className="data-table !border-t-0" aria-label="simple table">
        <TableHead className="table-head">
          <TableRow className="table-row">
            <TableCell className="table-cell col1">
              {(userType==="admin" || userType==="superadmin") && <Checkbox
                color="primary"
                indeterminate={
                  selected.length > 0 && selected.length < totalHRs
                }
                checked={data.length > 0 && selected.length === totalHRs}
                onChange={handleSelectAllClick}
                inputProps={{
                  "aria-label": "select all rows",
                }}
              />}
            </TableCell>
            <TableCell className="table-cell !font-bold !text-lg">
              COMPANY
            </TableCell>
            {(userType==="admin" || userType==="superadmin") && <TableCell className="table-cell-actions"></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => {
              return (
                <React.Fragment key={row._id}>
                  <TableRow hover className="table-row">
                    <TableCell className="table-cell col1">
                      <IconButton onClick={() => toggleRow(row._id)}>
                        {open[row._id] ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                    </TableCell>
                    <TableCell className="table-cell !text-md">{row.name}</TableCell>
                    {(userType==="admin" || userType==="superadmin") && <TableCell className="table-cell-actions" align="right">
                      <IconButton
                        onClick={() => handleOpenModal(row, "company")}
                      >
                        <Edit />
                      </IconButton>
                      <Checkbox
                        color="primary"
                        indeterminate={
                          row.HRs.length === 0 ||
                          (selected.filter((id) =>
                            row.HRs.some((hr) => hr._id === id)
                          ).length > 0 &&
                            selected.filter((id) =>
                              row.HRs.some((hr) => hr._id === id)
                            ).length < row.HRs.length)
                        }
                        checked={row.HRs.every((hr) =>
                          selected.includes(hr._id)
                        )}
                        onChange={(event) =>
                          handleSelectCompanyHRs(event, row)
                        }
                        inputProps={{
                          "aria-label": "select all HRs for this company",
                        }}
                      />
                    </TableCell>}
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell
                      style={{padding: 0 }}
                      colSpan={5}
                    >
                      <Collapse
                        in={open[row._id]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <TableContainer className="nested-table-container">
                          <MuiTable size="small" aria-label="hr details" className="nested-table">
                            <TableHead className="nested-table-head">
                              <TableRow className="nested-table-row">
                                <TableCell className="nested-table-cell !border-l-0">HR NAME</TableCell>
                                <TableCell className="nested-table-cell">HR CONTACT</TableCell>
                                <TableCell className="nested-table-cell">HR EMAIL</TableCell>
                                <TableCell className="nested-table-cell">HR LinkedIn</TableCell>
                                <TableCell className="nested-table-cell">HR LastContacted</TableCell>
                                <TableCell className={`nested-table-cell ${userType==="executive" && '!border-r-0'}`} style={{ width: '10%' }}>HR RESPONSE</TableCell>
                                {(userType==="admin" || userType==="superadmin") && 
                                  <TableCell className="nested-table-cell nested-table-cell-actions">  </TableCell>}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Array.isArray(row.HRs) &&
                                row.HRs.map((hr) => (
                                  <TableRow key={hr._id} className="nested-table-row">
                                    <TableCell className="nested-table-cell !border-l-0">{hr.name}</TableCell>
                                    <TableCell className="nested-table-cell !pl-12">
                                      {hr.phone ? (
                                        <button className=" contact-button" onClick={() => handleContactClick(hr._id, hr.phone, hr.name, hr.email, row.name, hr.response)}>
                                          <MdPhoneInTalk size={30} className="icon-style"/>
                                        </button>
                                      ) : null}
                                    </TableCell>
                                    <TableCell className="nested-table-cell">
                                      <button className="email-button" onClick={() => handleEmailClick(hr.email)}>
                                        {hr.email}
                                      </button>
                                    </TableCell>
                                    <TableCell className="nested-table-cell">
                                    {hr.linkedIn ? (
                                      <a
                                        href={hr.linkedIn}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="linkedin-link"
                                      >
                                        LinkedIn
                                      </a>
                                      ) : (
                                      "No LinkedIn"
                                      )}
                                    </TableCell>                                          
                                    <TableCell className="nested-table-cell"> 
                                      {updatingLastContacted && hr._id===lastContactedUpdatingHrId? 
                                        <CircularProgress size={10} />
                                        :
                                      <button onClick={()=>{
                                        setLastContactedPopupVisible(true);
                                        setEditData(hr);
                                      }}>  
                                      {hr.lastContacted ? 
                                        new Date(hr.lastContacted).toLocaleString(undefined, {
                                          year: 'numeric',
                                          month: 'numeric',
                                          day: 'numeric',
                                          hour: 'numeric',
                                          minute: 'numeric',
                                          hour12: true,
                                        }) : "Not Contacted"
                                      }
                                      </button>}
                                    </TableCell>
                                    <TableCell className={`nested-table-cell !pl-12 flex items-center justify-center ${userType==="executive" && '!border-r-0'}`}>
                                    {updatingResponse && hr._id===responseUpdatingHrId? 
                                        <CircularProgress size={10} />
                                        :
                                      <IconButton onClick={() => handleOpenResponseDialog(hr)}>
                                        <IoEye size={24} className="text-primary" /> 
                                      </IconButton>}
                                    </TableCell>
                                    {(userType==="admin" || userType==="superadmin") && 
                                    <TableCell className="nested-table-cell nested-table-cell-actions" align="right">
                                      <IconButton
                                        onClick={() =>
                                          handleDelete([hr._id])
                                        }
                                      >
                                        <DeleteOutline />
                                      </IconButton>
                                      <IconButton
                                        onClick={() =>
                                          handleOpenModal(hr, "hr")
                                        }
                                      >
                                        <Edit />
                                      </IconButton>
                                      <Checkbox
                                        color="primary"
                                        checked={isSelected(hr._id)}
                                        onClick={(event) =>
                                          handleClick(event, hr._id)
                                        }
                                      />
                                    </TableCell>}
                                  </TableRow>
                                ))}
                            </TableBody>
                          </MuiTable>
                        </TableContainer>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }} className="empty-row">
              <TableCell colSpan={15} />
            </TableRow>
          )}
        </TableBody>
      </MuiTable>
    </TableContainer>
    <CustomPagination
      rowsPerPage={rowsPerPage}
      totalRows={filteredData.length}
      currentPage={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      className="custom-pagination"
    />
  </Paper>
)}

      {/* Dialog for Editing */}
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseModal}
        maxWidth="sm"
        fullWidth
        className="edit-dialog"
        PaperProps={{ sx: { borderRadius: "1.5rem" } }}
      >
        <div className="inside-dialog">
        <DialogTitle className="edit-dialog-title">
          <Edit/> {modalType === "company" ? " Edit Company" : " Edit HR"}
        </DialogTitle>
        <hr className="edit-dialog-divider"/>
        <DialogContent className="edit-dialog-content">
          {modalType === "company" ? (
            <>
              <div className="edit-label-input-group">
                <label className="edit-label">Company</label>
                <input
                  name="name"
                  value={editData.name || ""}
                  onChange={handleEditChange}
                  fullWidth
                  margin="normal"
                  className="edit-input" // Custom className
                />
              </div>
            </>
          ) : (
            <>
              <div className="edit-label-input-group">
                <label className="edit-label">HR Name</label>
              <input
                name="name"
                value={editData.name || ""}
                onChange={handleEditChange}
                fullWidth
                margin="normal"
                className="edit-input"
              />
              </div>
              <div className="edit-label-input-group">
                <label className="edit-label">Phone</label>
              <input
                name="phone"
                value={editData.phone || ""}
                onChange={handleEditChange}
                onBlur={handleBlur}
                fullWidth
                placeholder="1234567890"
                type="phone"
                margin="normal"
                required
                className="edit-input"
              />
              </div>
                  {noValidPhone && <p className="invalid-details-text">Invalid phone number. Must be 10 digits.</p>}

              <div className="edit-label-input-group">
              <label className="edit-label">Email</label>
              <input
                name="email"
                value={editData.email || ""}
                onChange={handleEditChange}
                onBlur={handleBlur}
                fullWidth
                placeholder="user@example.com"
                type="email"
                margin="normal"
                required
                className="edit-input"
              />
              </div>
              {noValidEmail && <p className="invalid-details-text">Invalid email address.</p>}
              {/* LinkedIn Field */}
              <div className="edit-label-input-group">
                <label className="edit-label">LinkedIn</label>
                <input
                  name="linkedIn"
                  value={editData.linkedIn || ""}
                  onChange={handleEditChange}
                  fullWidth
                  margin="normal"
                  className="edit-input"
                />
              </div>
         {/* Last Contacted Field */}
         {/* <div className="edit-label-input-group">
            <label className="edit-label">Last Contacted</label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                value={editData.lastContacted ? dayjs(editData.lastContacted) : null}
                onChange={(newValue) => handleEditChange({ target: { name: 'lastContacted', value: newValue } })}
                fullWidth
                renderInput={(params) => <input {...params} placeholder="Pick Date" fullWidth margin="normal" className="edit-input"/>}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
                className="edit-input"
              />
            </LocalizationProvider>
          </div> */}
            </>
          )}
          {errorMessage && <p className="invalid-details-text mt-2 ml-4">{errorMessage}</p>}
        </DialogContent>
        <DialogActions className="edit-dialog-actions">
          <Button onClick={handleSaveChanges} className="edit-ok-button" disabled={updatingDetails}>
          {
          updatingDetails?
            <CircularProgress size={10} />
            :"OK"
          }
          </Button>
          <Button onClick={handleCloseModal} className="edit-cancel-button" disabled={updatingDetails}>
            Cancel
          </Button>
        </DialogActions>
        </div>
      </Dialog>
    <Dialog open={popupVisible} onClose={closeResponsePopup} PaperProps={{ sx: { borderRadius: "1rem" } }}>
      <div className="inside-dialog !w-[500px]">
    <DialogTitle className="edit-dialog-title">Did the HR Respond?</DialogTitle>
    <hr className="contact-dialog-divider"/>
    <DialogContent>
      <Typography variant="body1" className="contact-dialog-text">
        <span className="edit-label !py-0">Name:</span> {activeHR?.hrName}
      </Typography>
      { activeHR?.hrEmail && 
      <Typography variant="body1" className="contact-dialog-text">
        <span className="edit-label !py-0">Email:</span> {activeHR?.hrEmail}
      </Typography>
      }
      <Typography variant="body1" className="contact-dialog-text">
        <span className="edit-label !py-0">Phone No:</span> {activeHR?.hrPhone}
      </Typography>
      <Typography variant="body1" className="contact-dialog-text">
        <span className="edit-label !py-0">Company:</span> {activeHR?.hrCompany}
      </Typography>
      <div className="edit-label-input-group">
      <label className="edit-label">Response:</label>
          <Autocomplete
            freeSolo
            options={hrResponses}
            groupBy={(option) => option.group}
            getOptionLabel={(option) => option.title}
            inputValue={hrResponse || ""}
            onInputChange={(event, newInputValue) => {
              setHrResponse(newInputValue);
            }}
            onChange={(event, value)=>{
              let newValue = (value?(value.title?(value.title.length>0? value.title: ""):""):"");
              setHrResponse(newValue);
            }}
            className="!w-[500px] !p-0 edit-input add-company-input autocomplete-input"
            renderInput={(params) => 
              <TextField 
                {...params} 
                name="name"
                multiline
                maxRows={10}
                value={hrResponse || ""}
                onChange={(event, value)=>{
                  setHrResponse(event.target.value)
                }}
                fullWidth
                className="!w-[100%] !p-0 !border-0 autocomplete-input"
                InputProps={{
                  ...params.InputProps,
                  style: {
                    whiteSpace: 'pre-wrap', // Ensure the text wraps inside the input
                    wordWrap: 'break-word', // Break long words to fit the width
                  },
                }}
              />}
            renderGroup={(params) => (
              <li key={params.key}>
                <div className="autocomplete-group">{params.group}</div>
                <ul className="autocomplete-item">{params.children}</ul>
              </li>
            )}
            sx={{ width: 400 }}
          />
        </div>
      
    </DialogContent>
    <hr className="contact-dialog-divider"/>
    <DialogActions>
      <Button onClick={() => handleHRResponse(true, hrResponse)} className="edit-ok-button" color="primary">
        Yes
      </Button>
      <Button onClick={() => handleHRResponse(false, hrResponse)} className="edit-cancel-button" color="secondary">
        No
      </Button>
    </DialogActions>
    </div>
  </Dialog>
  <Dialog 
    open={filterOpen} 
    onClose={() => setFilterOpen(false) } 
    PaperProps={{ sx: { borderRadius: "1.5rem" } }}
  >
    <FilterComponent 
      branches={branchesList} 
      statuses={statuses} 
      appliedFilters={filters}
      onFilterChange={handleFilterChange} 
      onClose={() => setFilterOpen(false)} // Pass the onClose function
      showStatuses={false}
      showBranches={false}
    />
  </Dialog>

      <DeleteConfirmation
        showModal={showModal}
        hideModal={() => setShowModal(false)}
        confirmModal={() => confirmModal(deleteInfo, userType)}
        toDelete={deleteInfo}
        role={userType}
        message="Are you sure you want to Delete the Selected HR(s)?"
        deletingFromTable={true}
      />

      <DeleteConfirmation
        showModal={showCompanyModal}
        hideModal={() => setShowCompanyModal(false)}
        confirmModal={() => handleDeleteCompany(companyToDelete)}
        toDelete={companyToDelete}
        role={userType}
        message="Are you sure you want to delete this company?"
      />

      <Dialog
        open={lastContactedPopupVisible}
        onClose={()=>{
          setEditData({});
          setLastContactedPopupVisible(false);
        }}
        maxWidth="sm"
        fullWidth
        className="edit-dialog"
        PaperProps={{ sx: { borderRadius: "1.5rem" } }}
      >
        <div className="inside-dialog">
        <DialogTitle className="edit-dialog-title">
          <Edit/> Update Last Contacted
        </DialogTitle>
        <hr className="edit-dialog-divider"/>
        <DialogContent className="edit-dialog-content">
         {/* Last Contacted Field */}
         <div className="edit-label-input-group">
          <label className="edit-label">Last Contacted</label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              value={editData.lastContacted ? dayjs(editData.lastContacted) : null}
              onChange={(newValue) => handleEditChange({ target: { name: 'lastContacted', value: newValue } })}
              fullWidth
              className="edit-input !w-[calc(100%-100px)] !p-0 !border !border-[#111] edit-lastcontacted-input"
              renderInput={(params) => <input 
                                        {...params} 
                                        fullWidth 
                                        margin="normal" 
                                        className="edit-input !w-[100%] !border !border-[#111]"
                                      />}
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
              }}
            />
          </LocalizationProvider>
        </div>
        </DialogContent>
        <DialogActions className="edit-dialog-actions">
          <Button onClick={()=>handleDateChange(editData._id, editData.lastContacted, editData.response || "")} className="edit-ok-button" disabled={updatingLastContacted}>
          {
          updatingLastContacted?
            <CircularProgress size={10} />
            :"OK"
          }
          </Button>
          <Button onClick={()=>{
            setEditData({});
            setLastContactedPopupVisible(false);
          }} className="edit-cancel-button" disabled={updatingLastContacted}>
            Cancel
          </Button>
        </DialogActions>
        </div>
      </Dialog>
      <Dialog
        open={openResponseDialog}
        onClose={()=>{
          handleCloseResponseDialog()
        }}
        maxWidth="sm"
        fullWidth
        className="edit-dialog"
        PaperProps={{ sx: { borderRadius: "1.5rem" } }}
      >
        <div className="inside-dialog">
        <DialogTitle className="edit-dialog-title">
          <Edit/> Update HR Response
        </DialogTitle>
        <hr className="edit-dialog-divider"/>
        <DialogContent className="edit-dialog-content">
         {/* Last Contacted Field */}
        <div className="edit-label-input-group">
        <label className="edit-label">Response</label>
            <Autocomplete
              freeSolo
              options={hrResponses}
              groupBy={(option) => option.group}
              getOptionLabel={(option) => option.title}
              inputValue={editData.response || ""}
              onInputChange={(event, newInputValue) => {
                setEditData((prevData) => ({ ...prevData, response: newInputValue }));
              }}
              onChange={(event, value) => {
                // This handles the case where the user selects an option from the dropdown
                let newValue = (value ? (value.title ? value.title : "") : "");
                setEditData((prevData) => ({ ...prevData, response: newValue }));
              }}
              className="!w-[calc(100%-100px)] !p-0 edit-input add-company-input autocomplete-input"
              renderInput={(params) => 
                <TextField 
                {...params} 
                name="name"
                multiline
                maxRows={10}
                value={editData.response || ""}
                onChange={(event, value)=>{
                  setEditData((prevData) => ({ ...prevData, response: event.target.value}));
                }}
                fullWidth
                className="!w-[100%] !p-0 !border-0 autocomplete-input"
                InputProps={{
                  ...params.InputProps,
                  style: {
                    whiteSpace: 'pre-wrap', // Ensure the text wraps inside the input
                    wordWrap: 'break-word', // Break long words to fit the width
                  },
                }}
                />}
              renderGroup={(params) => (
                <li key={params.key}>
                  <div className="autocomplete-group">{params.group}</div>
                  <ul className="autocomplete-item">{params.children}</ul>
                </li>
              )}
              sx={{ width: 400 }}
            />
        </div>
        </DialogContent>
        <DialogActions className="edit-dialog-actions">
          <Button onClick={()=>handleDateChange(editData._id, editData.lastContacted, editData.response)} className="edit-ok-button" disabled={updatingResponse}>
          {
          updatingResponse?
            <CircularProgress size={10} />
            :"OK"
          }
          </Button>
          <Button onClick={()=>{
            handleCloseResponseDialog()
          }} className="edit-cancel-button" disabled={updatingResponse}>
            Cancel
          </Button>
        </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default Table;