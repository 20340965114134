import React, { useState } from "react";
import "./SignIn.css";
import { baseURL } from "../baseURL";
import CircularProgress from '@mui/material/CircularProgress'; // Import a loading spinner component
import Footer from "./Footer/Footer";

const SignIn = ({ onSignIn }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when the request starts
    setError("");

    try {
      const response = await fetch(baseURL + "/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      const responseData = await response.text();
      try {
        const data = JSON.parse(responseData);
        if (response.status === 200) {
          if (data.message === "Logged In") {
            const authtoken = data.authtoken;
            const type = data.type;
            localStorage.setItem("authToken", authtoken);
            localStorage.setItem("userType", type);
            onSignIn();
          } else {
            throw new Error("Invalid credentials");
          }
        } else {
          throw new Error("Failed to authenticate");
        }
      } catch (parseError) {
        throw new Error("Invalid response from server");
      }
    } catch (error) {
      console.error("Error:", error.message);
      setError(error.message);
    } finally {
      setLoading(false); // Set loading to false when the request is complete
    }
  };

  return (
    <div className="sign-in-wrapper">
      <div className="sign-in-container">
        {loading ? ( // Conditionally render loading spinner or sign-in form
          <div className="loading-container">
            <CircularProgress />
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="sign-in-form !text-black">
            <h2>Sign In</h2>
            <div className="form-group">
              <label htmlFor="username">Username:</label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="form-input"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="form-input"
                required
              />
            </div>
            <button type="submit" className="submit-button">
              Sign In
            </button>
          </form>
        )}
        {error && <div className="error-message">{error}</div>}
      </div>
      <Footer/>
    </div>
  );
};

export default SignIn;