import React from "react";
import Card from "./TeamCard";
// import "./Team.css"; // Assuming you will style it

const TeamPage = () => {
  const projectManagers = [
    {
      name: "Vansh Kumar",
      education: "B.Tech in Computer Science and Engineering",
      position: "Chief Technology Officer (CTO)",
      linkedIn: "https://www.linkedin.com/in/vansh1190/",
      github: "https://github.com/vansh1190",
      imageSrc: "vansh.webp",
    },
    {
      name: "Rajveer Singh",
      education: "B.Tech in Computer Science and Engineering",
      position: "Head of Database Operations",
      linkedIn: "https://www.linkedin.com/in/rajveer810214/",
      github: "https://github.com/Rajveer810214",
      imageSrc: "rajveer.webp",
    },
    {
      name: "Adarsh Mishra",
      education: "B.Tech in Electronics and  Communication Engineering",
      position: "Coordinator + POC",
      linkedIn: "https://www.linkedin.com/in/adarshmishra26/",
      github: "https://github.com/AdarshMishra26",
      imageSrc: "adarshMishra.webp",
    },
    {
      name: "Sangam Arora",
      education: "B.Tech in Computer Science and Engineering",
      position: "Event and Hackathon Coordinator",
      linkedIn: "http://linkedin.com/in/sangamarora",
      github: "https://github.com/sangam2109",
      imageSrc: "sangam.webp",
    },
  ];

  const developers = [
    {
      name: "Devesh",
      position: "Backend Developer",
      education: "B.Tech in Information Technology",
      linkedIn: "https://www.linkedin.com/in/devesh-sharma04/",
      github: "https://github.com/daveydark/",
      imageSrc: "devesh.webp",
    },
    {
      name: "Sanya Srivastava",
      position: "Frontend Developer",
      education: "B.Tech in Information Technology",
      linkedIn: "https://www.linkedin.com/in/sanya-srivastava-b961b428a/",
      github: "https://github.com/Sanya0732",
      imageSrc: "sanya.webp",
    },
    {
      name: "Rahul Sachdeva",
      position: "Frontend Developer",
      education: "B.Tech in Computer Science and Engineering",
      linkedIn: "https://www.linkedin.com/in/rahul-sachdeva-961862247/",
      github: "https://github.com/Rahul-Sachdeva",
      imageSrc: "rahul.webp",
    },
    {
      name: "Saloni",
      position: "Frontend Developer",
      education: "B.Tech in Information Technology",
      linkedIn: "https://www.linkedin.com/in/saloni-832765266/",
      github: "https://github.com/saloni-16M",
      imageSrc: "saloni.webp",
    },
    {
      name: "Satbir Singh",
      position: "UI/UX Designer",
      education: "B.Tech in Information Technology",
      linkedIn: "https://www.linkedin.com/in/satbir-singh-486587254/",
      github: "https://github.com/Satbir-Singh-42",
      imageSrc: "satbir.webp",
    },
    {
      name: "Manveer Kaur",
      position: "UI/UX Designer",
      education: "B.Tech in Computer Science and Engineering",
      linkedIn: "https://www.linkedin.com/in/manveer-kaur-dhaliwal-906a27268/",
      github: "https://github.com/manveerdhaliwal",
      imageSrc: "manveer.webp",
    },
    {
      name: "Keshav",
      position: "Frontend Developer",
      education: "B.Tech in Computer Science and Engineering",
      linkedIn: "https://www.linkedin.com/in/keshav-garg-092748213/",
      github: "https://github.com/binaryfetch",
      imageSrc: "keshav.webp",
    },
  ];

  return (
    <div className="team-page">
      <h2 className="section-heading">Project Managers</h2>
      <div className="team-section">
        {projectManagers.map((member, index) => (
          <Card key={index} {...member} />
        ))}
      </div>

      <h2 className="section-heading">Developers</h2>
      <div className="team-section">
        {developers.map((member, index) => (
          <Card key={index} {...member} />
        ))}
      </div>
    </div>
  );
};

export default TeamPage;
